import { ContactType, DocumentType } from '@innedit/innedit-type';
import { ContactData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { DataContactsSelectProps } from '~/datas/props';

import { DataField } from '../../packages/formidable';

const DataContactsSelect: FC<DataContactsSelectProps> = ({
  espaceId,
  name,
  ...props
}) => {
  const [docs, setDocs] = useState<DocumentType<ContactType>[]>();

  useEffect(() => {
    let isMounted = true;

    const contactData = new ContactData({ espaceId });
    contactData
      .find()
      .then(newDocs => {
        if (isMounted) {
          setDocs(newDocs);
        }

        return true;
      })
      .catch(toast.error);

    return () => {
      isMounted = false;
    };
  }, [espaceId]);

  if (!docs) {
    return null;
  }

  return (
    <DataField
      {...props}
      componentType="select"
      name={name}
      options={docs.map(doc => ({
        label: doc.label,
        value: doc.id,
      }))}
    />
  );
};

export default DataContactsSelect;
