import objectHash from 'object-hash';
import { Box, DataProps, Group } from 'packages/formidable';
import Data from 'packages/formidable/components/Data';
import React, { FC, SyntheticEvent, useState } from 'react';

import Button, { ButtonProps } from '~/components/Button';
import Modal from '~/datas/Attributes/Modal';
import IconEdit from '~/icons/Edit';

export interface DataAttributesProps extends Omit<DataProps, 'componentType'> {
  displayMaxWidth?: boolean;
  formName: string;
  name: string;
  type?: 'edit' | 'show';
}
const DataAttributes: FC<DataAttributesProps> = function ({
  actions,
  display,
  displayMaxWidth = false,
  label,
  name,
  formName,
  params,
  type = 'edit',
  ...props
}) {
  let attributes = params && params[formName];
  if (attributes && params.sectionName) {
    attributes = (params.sectionName as string)
      .split('.')
      .reduce((acc, segment) => {
        if (undefined === acc) {
          return undefined;
        }

        const [newSegment, i] = segment.split('[');
        let tmpAcc = acc[newSegment];
        if (i) {
          tmpAcc = tmpAcc ? tmpAcc[i.slice(0, -1)] : undefined;
        }

        return tmpAcc;
      }, attributes as { [key: string]: any });
  }
  if (attributes && name) {
    attributes = attributes[name];
  }

  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOpenOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpenModal(o => !o);
  };

  const handleCloseOnClick = (event?: SyntheticEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    setOpenModal(false);
  };

  const ShowAttributes = attributes && Object.keys(attributes).length > 0 && (
    <div className="flex flex-col space-y-1.5">
      {Object.keys(attributes).map(key => {
        if (displayMaxWidth && 'maxWidth' === key) {
          return null;
        }

        return (
          <Data
            key={key}
            attributeType="input"
            componentType="attribute/display"
            formName={formName}
            label={key}
            name={`${name}.${key}`}
          />
        );

        //
        // return (
        //   <dl
        //     key={key}
        //     className="grid grid-cols-4 bg-light-500 py-1.5 px-3 rounded text-light-800"
        //   >
        //     <dt>{key}</dt>
        //     <dd className="col-span-3">
        //       {Array.isArray(attributes[key])
        //         ? attributes[key].map((v: string, index: number) => (
        //             <pre key={objectHash({ index, v })} className="text-[12px]">
        //               {v}
        //             </pre>
        //           ))
        //         : JSON.stringify(attributes[key])}
        //     </dd>
        //   </dl>
        // );
      })}
    </div>
  );

  const DisplayData = displayMaxWidth ? (
    <Data
      attributeType="select"
      className="border-none mt-1.5"
      componentType="attribute/display"
      fieldProps={{
        className: 'border-none bg-light-500 text-light-800',
      }}
      formName={formName}
      label="maxWidth"
      name={`${name}.maxWidth`}
      options={[
        { label: 'compact', value: 'compact' },
        { label: 'medium', value: 'medium' },
        { label: 'large', value: 'large' },
        { label: 'expanded', value: 'expanded' },
        { label: 'ultra', value: 'ultra' },
      ]}
    />
  ) : undefined;

  if ('show' === type) {
    return (
      <>
        {ShowAttributes}
        {DisplayData}
      </>
    );
  }

  return (
    <Box>
      {openModal && (
        <Modal
          closeOnClick={handleCloseOnClick}
          formName={formName}
          name={name}
          params={params}
          title={name}
        />
      )}
      <Group
        customInfos={
          <>
            {actions &&
              actions.length > 0 &&
              actions.map((action: ButtonProps) => (
                <Button
                  key={objectHash({ action })}
                  {...action}
                  variant="sub-action"
                />
              ))}
            <Button
              iconLeft={IconEdit}
              onClick={handleOpenOnClick}
              variant="link"
            />
          </>
        }
        display={display}
        title={label ?? name}
        {...props}
      >
        {/* Affichage des données enregistrées */}
        {ShowAttributes}
        {DisplayData}
      </Group>
    </Box>
  );
};

export default DataAttributes;
