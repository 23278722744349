import { DataWithChildren, DataWithChildrenProps } from 'packages/formidable';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { arrayPush } from 'redux-form';

import Medias from '~/components/Medias';

export interface DataMediasProps
  extends Omit<DataWithChildrenProps, 'componentType'> {
  accept?: string;
  attributesName?: string;
  espaceId: string;
  label?: string;
  listClassName?: string;
  name: string;
  parentCollectionName: string;
  parentId: string;
}

const DataMedias: FC<DataMediasProps> = ({
  accept,
  attributesName,
  datas,
  display,
  espaceId,
  formName,
  label,
  listClassName,
  name,
  params,
  parentCollectionName,
  parentId,
}) => {
  const dispatch = useDispatch();

  const handleOnChange = (values: string[]) => {
    // il y a eu un changement, donc on signal le changement dans le formulaire
    values.forEach(value => {
      dispatch(arrayPush(formName, name, value));
    });
  };

  return (
    <Medias
      accept={accept}
      attributesName={attributesName}
      display={display}
      espaceId={espaceId}
      formName={formName}
      listClassName={listClassName}
      onChange={handleOnChange}
      params={params}
      parentCollectionName={parentCollectionName}
      parentField={name}
      parentId={parentId}
      title={label ?? 'Médias'}
    >
      {datas && (
        <DataWithChildren
          componentType="box"
          datas={datas}
          formName={formName}
          params={params}
        />
      )}
    </Medias>
  );
};

export default DataMedias;
