import { DocumentType, FeatureType } from '@innedit/innedit-type';
import classnames from 'classnames';
import { DataProps, Field } from 'packages/formidable';
import { FeatureData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { FormSection } from 'redux-form';

import HOCGroup from '~/components/Group/HOC';
import MultiSelectCreate from '~/datas/Features/MultiSelect/Create';
import IconPlus from '~/icons/Plus';

import FeaturesMultiSelectSub from './Sub';

export interface FeaturesMultiSelectProps
  extends Omit<DataProps, 'componentType'> {
  espaceId: string;
  label?: string;
  name: string;
  orderDirection?: 'asc' | 'desc';
  orderField?: keyof FeatureType;
  type?: 'all' | 'parent';
}
const FeaturesMultiSelect: FC<FeaturesMultiSelectProps> = ({
  espaceId,
  display,
  label,
  name,
  orderDirection,
  orderField,
  type = 'all',
}) => {
  const [options, setOptions] = useState<DocumentType<FeatureType>[]>();

  useEffect(() => {
    const feature = new FeatureData({
      espaceId,
      orderDirection,
      orderField,
    });
    const wheres: { [key: string]: any } = {
      hidden: false,
    };

    if ('parent' === type) {
      wheres.parent = '';
    }

    const unsub: () => void = feature.watch(docs => setOptions(docs), {
      wheres,
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, orderDirection, orderField]);

  return (
    <HOCGroup display={display} label={label}>
      <div className="relative">
        <MultiSelectCreate
          buttonClassName="-top-[27px] right-0"
          buttonIcon={IconPlus}
          buttonSize="lg"
          espaceId={espaceId}
        />
      </div>
      {options && options.length > 0 && (
        <FormSection name={name}>
          {options.map((option, index) => (
            <React.Fragment key={`${name}.${option.id}`}>
              <div
                className={classnames(
                  'flex flex-col space-y-1 relative border-t border-b pt-1 pl-2 -mt-[1px]',
                  {
                    'bg-light-400': 1 === index % 2,
                  },
                )}
              >
                <label
                  className="flex flex-row space-x-2 items-center content-center"
                  htmlFor={`${name}.${option.id}`}
                >
                  <Field
                    component="input"
                    id={`${name}.${option.id}`}
                    name={option.id}
                    type="checkbox"
                  />
                  <span>{option.label}</span>
                </label>
                <MultiSelectCreate espaceId={espaceId} parent={option.id} />
              </div>
              {'parent' === type && (
                <FeaturesMultiSelectSub
                  espaceId={espaceId}
                  name={option.id}
                  orderDirection={orderDirection}
                  orderField={orderField}
                  parent={option.id}
                />
              )}
            </React.Fragment>
          ))}
        </FormSection>
      )}
      {(!options || 0 === options.length) && <p>Aucune caractéristique</p>}
    </HOCGroup>
  );
};

export default FeaturesMultiSelect;
