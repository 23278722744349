import { OrderProductType } from '@innedit/innedit-type';
import React, { FC } from 'react';

import Button from '~/components/Button';

interface ItemNoEditableProps {
  espaceId: string;
  item: OrderProductType;
}

const ItemNoEditable: FC<ItemNoEditableProps> = ({ espaceId, item }) => (
  <div className="grid grid-cols-9 gap-3">
    <div className="flex items-center">{item.isPhysical ? 'Oui' : 'Non'}</div>
    <div className="col-span-6 flex">
      {item.thumbnail && (
        <img alt={item.label} className="h-[60px] mr-3" src={item.thumbnail} />
      )}
      {item.id ? (
        <Button
          to={`/espaces/${espaceId}/produits/${item.id}/update/`}
          variant="link"
        >
          {item.label}
        </Button>
      ) : (
        <strong>{item.label}</strong>
      )}
    </div>
    <div className="col-span-2 flex justify-end items-center">
      {item.quantity || 1} x {item.price}€
    </div>
  </div>
);

export default ItemNoEditable;
