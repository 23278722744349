import React, { FC } from 'react';

interface MediaProps {
  className?: string;
  media: any; // TODO remettre MediaNode ou le nouveau type
  options?: string;
}

const Media: FC<MediaProps> = ({ className, media, options }) => {
  let newMedia = `${media.origin}/${media.pathname}`;

  if ('application/pdf' === media.type) {
    return <iframe src={newMedia} title={media.label} width="100%" />;
  }
  if (['image/png', 'image/jpeg'].includes(media.type)) {
    newMedia += options ? `?${options}` : '?w=320&h=320&c=max';
  }

  return <img alt="chargement" className={className} src={newMedia} />;
};

export default Media;
