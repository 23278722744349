import { Box } from 'packages/formidable';
import {
  ArticleData,
  client,
  ContactData,
  getTypesenseFilters,
  PageData,
  ProduitData,
} from 'packages/innedit';
import React, {
  FC,
  KeyboardEvent,
  SyntheticEvent,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { arrayPush, FieldArray } from 'redux-form';
import type { SearchResponse } from 'typesense/lib/Typesense/Documents';

import DataIdentificationPage from '~/datas/Associations/Page';
import DataAssociationsRender from '~/datas/Associations/Render';
import { DataAssociationsProps } from '~/datas/props';
import IconClose from '~/icons/Close';

import Button from '../../components/Button';
import Input from '../../components/Input';
import Popup from '../../components/Popup';
import IconSearch from '../../icons/Search';
import DataIdentificationArticle from './Article';
import DataIdentificationContact from './Contact';
import DataIdentificationProduct from './Product';

const ResultHeader: FC<{ count?: number; title: string }> = ({
  count,
  title,
}) => (
  <div className="flex justify-between">
    <span className="text-light-900">{title}</span>
    {undefined !== count && (
      <span className="bg-light-600 text-light-900 rounded-full text-[9px] w-4 h-4 flex items-center justify-center">
        {count}
      </span>
    )}
  </div>
);

const DataAssociations: FC<DataAssociationsProps> = ({
  collections = ['pages', 'produits'],
  espaceId,
  formName,
  name,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [q, setQ] = useState<string>('');
  const [results, setResults] = useState<SearchResponse<any>[]>();
  const dispatch = useDispatch();

  const search = async () => {
    if (q) {
      // On recherche dans les différentes collections si un document correspond
      const filter = getTypesenseFilters({
        espaceId,
      });

      const searchRequests: { searches: any[] } = {
        searches: [],
      };

      collections.forEach(collection => {
        switch (collection) {
          case 'articles': {
            const articleData = new ArticleData({ espaceId });
            searchRequests.searches.push({
              q,
              collection: 'articles',
              query_by: articleData.queryBy as string,
            });
            break;
          }

          case 'pages': {
            const pageData = new PageData({ espaceId });
            searchRequests.searches.push({
              q,
              collection: 'pages',
              query_by: pageData.queryBy as string,
            });
            break;
          }

          case 'produits': {
            const productData = new ProduitData({ espaceId });
            searchRequests.searches.push({
              q,
              collection: 'produits',
              query_by: productData.queryBy as string,
            });
            break;
          }

          case 'contacts': {
            const contactData = new ContactData({ espaceId });
            searchRequests.searches.push({
              q,
              collection: 'contacts',
              query_by: contactData.queryBy as string,
            });
            break;
          }
        }
      });

      const commonSearchParams = {
        q,
        filter_by: filter,
      };

      const result = await client.multiSearch.perform(
        searchRequests,
        commonSearchParams,
      );

      setResults(result.results as SearchResponse<any>[]);
    }
  };

  const handleOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setQ(value);

    if ('' === value) {
      setResults(undefined);
    }
  };

  const handleOnKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if ('Enter' === e.key) {
      e.preventDefault();

      search();
    }
  };

  const handleCloseOnClick = () => {
    setResults(undefined);
  };

  const handleResetOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setQ('');
    setResults(undefined);
  };

  const handleSearchOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    search();
  };

  const handleAssociationOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    const collection = event.currentTarget.getAttribute('data-collection');
    const id = event.currentTarget.getAttribute('data-id');
    const label = event.currentTarget.getAttribute('data-label');
    if (null !== collection && null !== id) {
      // TODO ajouter le document dans la liste des documents associés
      dispatch(
        arrayPush(formName, 'associations', {
          collection,
          id,
          label,
        }),
      );
      setResults(undefined);
      setQ('');
    }
  };

  const nbResults = results?.reduce((acc, result) => acc + result.found, 0);

  return (
    <div>
      <Box>
        <div>
          <div ref={ref} className="flex">
            <div className="flex-1 relative">
              <Input
                className="rounded-r-none pr-[36px]"
                onChange={handleOnChange}
                onKeyDown={handleOnKeyDown}
                value={q}
              />
              {q && (
                <Button
                  className="absolute top-[13px] right-[13px]"
                  color="tertiary"
                  iconLeft={IconClose}
                  onClick={handleResetOnClick}
                  variant="link"
                />
              )}
            </div>

            <Button
              className="rounded-l-none"
              color="neutral"
              iconLeft={IconSearch}
              onClick={handleSearchOnClick}
            />
          </div>
          {results && results.length > 0 && (
            <Popup
              isOpen
              onClose={handleCloseOnClick}
              width={ref.current?.offsetWidth}
            >
              <div className="flex flex-col space-y-2 flex-1">
                {results.map((result, ri) => {
                  if (0 === result.found) {
                    return null;
                  }

                  return (
                    <div key={`result_${collections[ri]}`}>
                      <ResultHeader
                        count={result.found}
                        title={t(
                          `identification.collection.${collections[ri]}.title`,
                        )}
                      />
                      {result &&
                        result.hits?.map((hit, index) => {
                          switch (collections[ri]) {
                            case 'articles':
                              return (
                                <DataIdentificationArticle
                                  key={`article_${hit.document.id}`}
                                  espaceId={espaceId}
                                  hit={hit}
                                  onClick={handleAssociationOnClick}
                                />
                              );
                            case 'contacts':
                              return (
                                <DataIdentificationContact
                                  key={`contact_${hit.document.id}`}
                                  espaceId={espaceId}
                                  hit={hit}
                                  onClick={handleAssociationOnClick}
                                />
                              );
                            case 'pages':
                              return (
                                <DataIdentificationPage
                                  key={`page_${hit.document.id}`}
                                  espaceId={espaceId}
                                  hit={hit}
                                  onClick={handleAssociationOnClick}
                                />
                              );
                            case 'produits':
                              return (
                                <DataIdentificationProduct
                                  key={`product_${hit.document.id}`}
                                  espaceId={espaceId}
                                  hit={hit}
                                  onClick={handleAssociationOnClick}
                                />
                              );

                            default:
                              return null;
                          }
                        })}
                    </div>
                  );
                })}
                {q && 0 === nbResults && (
                  <div className="flex justify-between">
                    <span>Aucun resultat</span>
                    <Button
                      color="neutral"
                      onClick={handleResetOnClick}
                      variant="link"
                    >
                      Effacer
                    </Button>
                  </div>
                )}
              </div>
            </Popup>
          )}
        </div>
      </Box>
      <FieldArray
        component={DataAssociationsRender}
        name={name}
        props={{
          espaceId,
          display: 'inside',
        }}
      />
    </div>
  );
};

export default DataAssociations;
