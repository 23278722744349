import { DataArray, DataFieldProps } from 'packages/formidable';
import React, { FC } from 'react';

export interface DataAttributeProps
  extends Omit<DataFieldProps, 'componentType'> {
  attributeType: string;
}
const DataAttribute: FC<DataAttributeProps> = function ({
  attributeType,
  name,
  formName,
  label,
  params,
  ...props
}) {
  return (
    <DataArray
      addButton
      bodyProps={{
        className: 'flex',
      }}
      datas={[
        {
          componentType: attributeType,
          labelShow: false,
          ...props,
        },
      ]}
      formName={formName}
      groupProps={{
        bodyProps: {
          className: 'flex flex-col space-y-3 p-0 border-0',
        },
        title: label ?? name,
        titleProps: {
          className: 'text-base font-light',
        },
      }}
      name={name}
      params={params}
      removeButton
    />
  );
};

export default DataAttribute;
