import { DocumentType, PriceType } from '@innedit/innedit-type';
import { navigate } from 'gatsby';
import { DataProps } from 'packages/formidable';
import { PriceData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import Button from '~/components/Button';
import HOCGroup from '~/components/Group/HOC';
import IconPlus from '~/icons/Plus';
import price from '~/params/produit/price/index.json';

import PricesDefault from './Default';
import PricesList from './List';

export interface DataPricesCrudProps extends Omit<DataProps, 'componentType'> {
  espaceId: string;
  display?: 'inside' | 'group';
  docCollectionName: string;
  docId: string;
  editPathname: string;
  formName: string;
  title?: string;
}

const DataPricesCrud: FC<DataPricesCrudProps> = ({
  espaceId,
  display,
  docCollectionName,
  docId,
  editPathname,
  formName,
  title,
}) => {
  const [docs, setDocs] = useState<DocumentType<PriceType>[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;
    let unsub: any;

    if (docCollectionName && docId) {
      setIsLoading(true);
      const featureData = new PriceData({
        espaceId,
        orderDirection: 'desc',
        orderField: 'datetime',
        parentCollectionName: docCollectionName,
        parentId: docId,
      });

      unsub = featureData.watch(newDocs => {
        if (isMounted) {
          setDocs(newDocs);
          setIsLoading(false);
        }
      }, {});
    }

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, docCollectionName, docId]);

  const handleAddOnClick = async (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const model = new PriceData({
      espaceId,
      params: price,
      parentCollectionName: docCollectionName,
      parentId: docId,
    });

    const newDoc: Partial<PriceType> = model.initialize();

    const doc = await model.create(newDoc);

    if (doc) {
      await navigate(`${editPathname + doc.id}/`);
    }
  };

  const handleRemoveOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    const model = new PriceData({
      espaceId,
      params: price,
      parentCollectionName: docCollectionName,
      parentId: docId,
    });

    const id = event.currentTarget.getAttribute('data-id');
    if (docs && id) {
      const doc = docs.find(d => d.id === id);
      if (doc) {
        await model.delete(doc.id);
      }
    }
  };

  // il faut vérifier la compatibilité entre les différents prix
  // il ne peut pas y avoir plusieurs prix avec le même tarif / type

  return (
    <HOCGroup
      customInfos={
        docId &&
        docs &&
        docs.length > 0 && (
          <Button
            iconLeft={IconPlus}
            onClick={handleAddOnClick}
            variant="sub-action"
          />
        )
      }
      display={display}
      title={docs && docs.length > 0 ? title || 'Prix' : ''}
    >
      {isLoading && <p>Chargement en cours</p>}
      <PricesDefault formName={formName} size={docs?.length ?? 0} />
      {docs && docs.length > 0 && (
        <PricesList
          docs={docs}
          editPathname={editPathname}
          removeOnClick={handleRemoveOnClick}
        />
      )}
    </HOCGroup>
  );
};

export default DataPricesCrud;
