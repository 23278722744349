import { DataSection } from 'packages/formidable';
import React, { FC, SyntheticEvent } from 'react';

import Button from '~/components/Button';
import IconDelete from '~/icons/Delete';
import { spacing } from '~/styles/theme';

interface ItemNoEditableProps {
  deleteOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  index: number;
  name: string;
}

const ItemEditable: FC<ItemNoEditableProps> = ({
  deleteOnClick,
  index,
  name,
}) => (
  <div className="flex space-x-1.5">
    <DataSection
      datas={
        [
          {
            cols: ['none', 11],
            componentType: 'grid',
            datas: [
              {
                componentType: 'input',
                labelShow: false,
                name: 'isPhysical',
                type: 'checkbox',
                wrapperProps: {
                  className: 'justify-center',
                },
              },
              {
                componentType: 'input',
                labelShow: false,
                name: 'label',
                placeholder: 'Libellé',
                wrapperProps: {
                  col: ['full', 6],
                },
              },
              {
                componentType: 'input',
                labelShow: false,
                name: 'quantity',
                placeholder: 'Quantity',
                type: 'number',
                wrapperProps: {
                  col: ['full', 2],
                },
              },
              {
                componentType: 'input',
                labelShow: false,
                name: 'price',
                placeholder: 'Prix',
                type: 'number',
                wrapperProps: {
                  col: ['full', 2],
                },
              },
            ],
            gap: spacing[1.5],
          },
        ] as any
      }
      name={name}
    />
    <Button
      color="neutral"
      datas={{
        'data-index': index,
      }}
      iconLeft={IconDelete}
      onClick={deleteOnClick}
      variant="solid"
    />
  </div>
);

export default ItemEditable;
