import {
  DocumentType,
  ProductSlotType,
  ProductVariantValuesType,
  ProduitType,
} from '@innedit/innedit-type';
import dayjs from 'dayjs';
import objectHash from 'object-hash';
import { DataProps } from 'packages/formidable';
import { ProduitData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import slug from 'slug';

import Button from '~/components/Button';
import HOCGroup from '~/components/Group/HOC';
import IconEdit from '~/icons/Edit';
import IconRedo from '~/icons/Redo';
import displayCurrency from '~/utils/displayCurrency';

export interface DataProductVariantsProps
  extends Omit<DataProps, 'componentType'> {
  espaceId: string;
  docId: string;
  formName: string;
}

const DataProductVariants: FC<DataProductVariantsProps> = function ({
  display,
  docId,
  espaceId,
  formName,
  label,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [variants, setVariants] = useState<DocumentType<ProduitType>[]>();

  useEffect(() => {
    let isMounted = true;
    const produitData = new ProduitData({
      espaceId,
      wheres: {
        kind: 'variant',
        parent: docId,
      },
    });

    const unsub = produitData.watch(docs => {
      if (isMounted) {
        setVariants(docs);
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [docId, espaceId]);

  const handleGenerateVariantsOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    // Il faut forcer l'enregistrement du produit car les déclinaisons sont associées aux options
    dispatch(submit(formName));

    const produitData = new ProduitData({ espaceId });

    const product = await produitData.findById(docId);

    const generatedValues: {
      label: string;
      values: (string | ProductSlotType)[];
    }[] = [];
    if (product.slots && product.slots.length > 0) {
      generatedValues.push({
        label: 'slot',
        values: [...product.slots],
      });
    }
    if (product.options && product.options.length > 0) {
      generatedValues.push(...product.options);
    }

    if (generatedValues && generatedValues.length > 0) {
      const promises = generatedValues
        .reduce((acc, field) => {
          const newAcc: ProductVariantValuesType[] = [];
          const fieldLabel = slug(field.label);

          if (0 === acc.length) {
            return field.values.map(value => {
              if ('string' === typeof value) {
                return {
                  options: {
                    [fieldLabel]: value,
                  },
                };
              }

              return {
                slot: value,
              };
            });
          }

          field.values.forEach(value => {
            acc.forEach(a => {
              if ('string' === typeof value) {
                // c'est une option
                const tmp: ProductVariantValuesType = {
                  ...a,
                  options: {
                    ...a.options,
                    [fieldLabel]: value as string,
                  },
                };
                newAcc.push(tmp);
              } else {
                // c'est un créneau
                newAcc.push({
                  ...a,
                  slot: value,
                });
              }
            });
          });

          return newAcc;
        }, [] as ProductVariantValuesType[])
        .map(variantValues => {
          const variantCode = objectHash({ ...variantValues });

          return produitData
            .find({
              wheres: {
                variantCode,
                deleted: false,
              },
            })
            .then(querySnapshot => {
              // Est-ce que la déclinaison existe ?
              if (0 < querySnapshot.length) {
                return undefined;
              }

              let newLabel = variantValues.slot
                ? `${dayjs(variantValues.slot.startDate).format(
                    'dddd D MMM YYYY',
                  )} à ${variantValues.slot.startTime}`
                : '';
              const tmpOptions = variantValues.options;
              if (tmpOptions && Object.keys(tmpOptions).length > 0) {
                newLabel = Object.keys(tmpOptions).reduce((acc, key) => {
                  const tmpLabel = tmpOptions[key];

                  return acc ? `${acc} | ${tmpLabel}` : tmpLabel;
                }, newLabel);
              }

              // la combinaison n'existe pas, il faut la créer
              const newVariant = produitData.initialize({
                variantCode,
                variantValues,
                hasGlobalPricing: false,
                kind: 'variant',
                label: newLabel,
                parent: docId,
              });

              return produitData.create(newVariant);
            });
        });

      await Promise.all(promises);
    }
  };

  return (
    <HOCGroup
      addIcon={IconRedo}
      addOnClick={handleGenerateVariantsOnClick}
      display={display}
      title={label ?? 'Liste des déclinaisons'}
    >
      <div>
        {undefined === variants && <p>Chargement en cours des déclinaisons</p>}
        {0 === variants?.length && <p>Aucune déclinaison</p>}
        {undefined !== variants && variants.length > 0 && (
          <div>
            {variants
              ?.sort((a, b) => a.label.localeCompare(b.label))
              .map(variant => (
                <div
                  key={variant.id}
                  className="flex justify-between items-center"
                >
                  <div className="flex space-x-3 items-center">
                    <strong>{variant.label}</strong>
                  </div>

                  <div className="flex space-x-3 items-center">
                    {variant.hasGlobalPricing && <span>Prix global</span>}
                    {!variant.hasGlobalPricing && variant.price && (
                      <span>
                        {variant.price &&
                          `${variant.price} ${displayCurrency(
                            variant.priceCurrency,
                          )}`}
                      </span>
                    )}

                    {variant.hasInventory && (
                      <span className="text-neutral-500 text-xs">
                        {t('product.variants.qty-available', {
                          count: variant.qtyAvailable,
                        })}
                      </span>
                    )}
                    <Button
                      iconLeft={IconEdit}
                      size="sm"
                      to={`/espaces/${espaceId}/produits/${variant.id}/variant`}
                      variant="link"
                    />
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </HOCGroup>
  );
};

export default DataProductVariants;
