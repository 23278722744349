import { DataType, DocumentType, MediaType } from '@innedit/innedit-type';
import { Link } from 'gatsby';
import objectHash from 'object-hash';
import { Box, Data, DataProps } from 'packages/formidable';
import { GroupBody, GroupTitle } from 'packages/formidable/components/Group';
import { MediaData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Media from '~/components/Media';

export interface DataMediaParentProps extends Omit<DataProps, 'componentType'> {
  datas?: DataType[];
  espaceId: string;
  parentCollectionName: string;
  parentId: string;
}

const DataMediaParent: FC<DataMediaParentProps> = ({
  datas,
  espaceId,
  parentCollectionName,
  parentId,
  title,
  titleProps,
  ...props
}) => {
  // const [document, setDocument] = useState<DocumentType<any>>();
  const [medias, setMedias] = useState<DocumentType<MediaType>[]>();

  useEffect(() => {
    let isMounted = true;

    const mediaData = new MediaData({
      espaceId,
      parentCollectionName,
      parentId,
    });

    mediaData
      .find()
      .then(docs => {
        if (isMounted) {
          setMedias(docs);
        }

        return isMounted;
      })
      .catch(toast.error);

    return () => {
      isMounted = false;
    };
  }, [parentCollectionName, parentId]);

  const page = 1;

  return (
    <Box>
      {page && (
        <>
          {title && <GroupTitle {...titleProps}>{title}</GroupTitle>}
          <GroupBody className="bg-light-500">
            <div className="grid grid-cols-3 gap-1.5">
              {/* {page.medias && page.medias.length > 0 && ( */}
              {/*  <div> */}
              {/*    <Media */}
              {/*      className="rounded" */}
              {/*      media={page.medias[0]} */}
              {/*      options="w=65&h=65" */}
              {/*    /> */}
              {/*  </div> */}
              {/* )} */}

              {/* <div className="col-span-2"> */}
              {/*  <h2 className="text-md">{page.name}</h2> */}
              {/*  <Button */}
              {/*    to={`/espaces/${espaceId}/channels/${channelId}/pages/${parentId}/update/`} */}
              {/*    variant="link" */}
              {/*  > */}
              {/*    Retour au document */}
              {/*  </Button> */}
              {/* </div> */}
              {medias &&
                medias.map(media => (
                  <Link
                    key={media.id}
                    to={`/espaces/${espaceId}/medias/${parentCollectionName}/${parentId}/${media.id}/update`}
                  >
                    <Media
                      media={{
                        ...media,
                        origin: String(process.env.GATSBY_IMAGES_DOMAIN_NAME),
                        pathname: media.fullPath,
                      }}
                      options="c=contain&r=1&w=100&b=ffffff"
                    />
                  </Link>
                ))}
            </div>
            {datas &&
              datas.length > 0 &&
              datas.map(data => (
                <Data
                  key={objectHash(data)}
                  {...props}
                  {...data}
                  params={{
                    ...props.params,
                    parentCollectionName,
                    parentId,
                  }}
                />
              ))}
          </GroupBody>
        </>
      )}
    </Box>
  );
};

export default DataMediaParent;
