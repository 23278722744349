import styled from '@emotion/styled';
import React, { FC, useEffect, useRef } from 'react';

import { borderRadius, colors, spacing } from '../../styles/theme';

const PopupEL = styled.div`
  position: absolute;
  background: ${colors.light[50]};
  border-radius: ${borderRadius.s};

  margin-top: ${spacing[3]};
  padding: ${spacing[3]};
  box-shadow: rgba(23, 24, 24, 0.05) -1px 0px 20px 0px,
    rgba(0, 0, 0, 0.15) 0px 1px 5px 0px;
  z-index: 500;

  min-width: 6rem;
  max-height: 300px;
  overflow-y: auto;
`;

interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
  width?: number;
}

const Popup: FC<PopupProps> = ({ children, isOpen, onClose, width }) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <PopupEL ref={ref} style={{ width }}>
      {children}
    </PopupEL>
  );
};

export default Popup;
