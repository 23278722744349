import { DocumentType, PaymentType } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent } from 'react';

import Button from '~/components/Button';

interface ItemRequiresPaymentMethodProps {
  espaceId: string;
  paiement: DocumentType<PaymentType>;
}

const ItemRequiresPaymentMethod: FC<ItemRequiresPaymentMethodProps> =
  function ({ espaceId, paiement }) {
    const handleOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    return (
      <div className="mt-6 flex justify-center">
        <Button
          color="neutral"
          onClick={handleOnClick}
          size="sm"
          variant="outline"
        >
          Envoyer un lien de paiement
        </Button>
      </div>
    );
  };

export default ItemRequiresPaymentMethod;
