import { DocumentType, PriceType } from '@innedit/innedit-type';
import React, { createElement, ElementType, FC } from 'react';
import { useTranslation } from 'react-i18next';

import getLabel from '~/components/Price/getLabel';

const Price: FC<{
  as?: ElementType;
  asProps?: any;
  data: DocumentType<PriceType>;
  showDisplayMode?: boolean;
  showType?: boolean;
}> = ({ as, asProps = {}, data, showDisplayMode = true, showType = true }) => {
  const { t } = useTranslation();

  const label = getLabel(data, t);

  if (!showType && !showDisplayMode) {
    return createElement(as || React.Fragment, asProps, label);
  }

  const priceInfo = (
    <div className="flex space-x-3">
      <span>{label}</span>
      {showType && data?.type && (
        <span className="italic text-light-800">
          {t(`prices.types.${data.type}`)}
        </span>
      )}
      {showType &&
        (!data.type || 'custom' === data.type) &&
        data.description && (
          <span className="italic text-light-800">{data.description}</span>
        )}
    </div>
  );

  if (!showDisplayMode) {
    return createElement(as || React.Fragment, asProps, priceInfo);
  }

  return (
    <div className="flex-1 flex justify-between items-center">
      {priceInfo}
      <span className="text-xs">
        {t(`prices.display-modes.${data.displayMode}`)}
      </span>
    </div>
  );
};

export default Price;
