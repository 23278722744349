import { DataProps } from 'packages/formidable';
import React, { FC } from 'react';

import Media from '~/components/Media';

export interface DataMediaProps extends Omit<DataProps, 'componentType'> {
  formName: string;
}

const DataMedia: FC<DataMediaProps> = function ({ formName, params }) {
  return params[formName] ? (
    <Media
      media={{
        ...params[formName],
        origin: String(process.env.GATSBY_IMAGES_DOMAIN_NAME),
        pathname: params[formName].fullPath,
      }}
    >
      Media
    </Media>
  ) : null;
};

export default DataMedia;
