import { OrderType } from '@innedit/innedit-type';
import { DataProps } from 'packages/formidable';
import { OrderData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import StyledResume from '../../components/Resume';

export interface DataOrdersResumeProps
  extends Omit<DataProps, 'componentType'> {
  espaceId: string;
}

const DataOrdersResume: FC<DataOrdersResumeProps> = ({ espaceId, params }) => {
  const [order, setOrder] = useState<OrderType>();
  const id = params && params.docId;

  useEffect(() => {
    let isMounted = true;
    const orderData = new OrderData({ espaceId });
    const unsub = orderData.watchById(id, doc => {
      if (isMounted) {
        setOrder(doc);
      }
    });

    return () => {
      isMounted = false;

      if (unsub) {
        unsub();
      }
    };
  }, [id]);

  if (!order) {
    return null;
  }

  const {
    deliveryEstimation,
    deliveryMethod,
    products,
    carrierIsPaidDirectly,
    paymentAmountReceived,
    paymentAmountRefunded,
    totalAmount,
  } = order;

  let { deliveryAmount } = order;

  if ('store' === deliveryMethod) {
    deliveryAmount = 0;
  } else if (
    undefined === deliveryAmount &&
    undefined !== deliveryEstimation &&
    deliveryEstimation >= 0
  ) {
    deliveryAmount = deliveryEstimation;
  }

  const resteAPayer = totalAmount - (paymentAmountReceived ?? 0); // - (paymentAmountRefunded ?? 0);
  if (products) {
    const produitsAmount = OrderData.calculateProduitsAmount(products);

    return (
      <StyledResume>
        <div className="analytics">
          <div className="total">
            <span className="label">Total</span>
            <span>{totalAmount}€</span>
          </div>
          <div className="infos">
            <h2>Détails</h2>
            <div className="datas">
              <div className="produits">
                <span>{produitsAmount}€</span>
                <span className="label">Produits</span>
              </div>
              {undefined !== paymentAmountReceived && (
                <div className="payment">
                  <span>{paymentAmountReceived}€</span>
                  <span className="label">Paiement</span>
                </div>
              )}
              {undefined !== paymentAmountRefunded &&
                paymentAmountRefunded > 0 && (
                  <div className="payment">
                    <span>{paymentAmountRefunded}€</span>
                    <span className="label">Remboursement</span>
                  </div>
                )}
              {'carrier' === deliveryMethod && (
                <div>
                  {deliveryAmount === undefined && <span>Sur devis</span>}
                  {deliveryAmount !== undefined && 0 === deliveryAmount && (
                    <span>Offerte</span>
                  )}
                  {deliveryAmount !== undefined && 0 < deliveryAmount && (
                    <span>{deliveryAmount}€</span>
                  )}
                  <span className="label">
                    Livraison {!!carrierIsPaidDirectly && '(au transporteur)'}
                  </span>
                </div>
              )}
              {resteAPayer !== 0 && (
                <div className="payment">
                  <span>{resteAPayer}€</span>
                  <span className="label">Reste à payer</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div />
      </StyledResume>
    );
  }

  return null;
};

export default DataOrdersResume;
