import { DocumentType, UserType } from '@innedit/innedit-type';
import { diff } from 'deep-object-diff';
import FirebaseAuth, {
  isSignInWithEmailLink,
  onAuthStateChanged,
  RecaptchaVerifier,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInAnonymously,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithPhoneNumber,
  updateEmail,
  updatePassword,
  updateProfile,
} from 'firebase/auth';
import FirebaseFirestore, {
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import compact from 'lodash/compact';

import { auth, functions } from '../../config/firebase';
import { InneditError } from '../functions';
import Model, { ModelProps } from '../Model';

class User extends Model<UserType> {
  constructor(props?: Omit<ModelProps<UserType>, 'collectionName'>) {
    super({
      ...props,
      canDoSearch: true,
      collectionName: 'users',
      labelFields: ['firstName', 'lastName', 'phone'],
      orderDirection: props?.orderDirection || 'desc',
      orderField: props?.orderField || 'createdAt',
    });
  }

  public initialize(data?: Partial<UserType>): Partial<UserType> {
    return super.initialize({
      ...data,
      stripeAccountHidden: data?.stripeAccountHidden || false,
      stripeAccountVerified: data?.stripeAccountVerified || false,
    });
  }

  static getReCAPTCHA(next?: (response: string) => void): void {
    auth.languageCode = 'fr';

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        callback: (response: string) => {
          if (next) {
            next(response);
          }
        },
        size: 'invisible',
      },
      auth,
    );
  }

  public async signUp(data: Partial<UserType>): Promise<string> {
    const user = auth.currentUser;
    if (!user) {
      throw new InneditError(
        'sign-up/auth-user-logout',
        "L'utilisateur doit être connecté pour enregistrer un document",
      );
    }

    const ref = doc(this.getCollectionRef(), user.uid);
    await setDoc(
      ref,
      this.clean(
        { ...data, createdByUser: user.uid, uid: user.uid },
        true,
      ) as UserType,
    );

    return ref.id;
  }

  static sendPasswordResetEmail(email: string): Promise<void> {
    return sendPasswordResetEmail(auth, email);
  }

  public async edition(
    id: string,
    values: Partial<UserType> & { password?: string; password2?: string },
  ): Promise<void> {
    const ref = doc(this.getCollectionRef(), id);
    const user = await getDoc(ref);
    const { password, password2, ...others } = values;
    const emails = others.emails ?? [];
    const diffValues: any = diff(user.data() || {}, others);

    const { currentUser } = auth;
    if (currentUser) {
      if (password && password === password2) {
        await updatePassword(currentUser, password);
      }

      if (diffValues.email && others.email) {
        await updateEmail(currentUser, others.email);
        emails.push(user.get('email'));
      }

      if (diffValues.firstName || diffValues.lastName) {
        await updateProfile(currentUser, {
          displayName: compact([others.firstName, others.lastName])
            .join(' ')
            .trim(),
        });
      }
    }

    return this.set(id, { ...others, emails });
  }

  public async findByEmail(
    email: string,
  ): Promise<FirebaseFirestore.DocumentSnapshot<UserType> | null> {
    const constraints = [
      where('email', '==', email.toLowerCase().trim()),
      where('deleted', '==', false),
    ];

    const q = query(this.getCollectionRef(), ...constraints);

    const querySnapshot = await getDocs(q);

    if (querySnapshot && !querySnapshot.empty) {
      return querySnapshot.docs[0];
    }

    return null;
  }

  public async findByUid(uid: string): Promise<DocumentType<UserType>> {
    const constraints = [
      where('deleted', '==', false),
      where('uid', '==', uid),
    ];

    const q = query(this.getCollectionRef(), ...constraints);

    const querySnapshot = await getDocs(q);

    if (0 === querySnapshot.size) {
      throw new InneditError('user/not-exists', "L'utilisateur n'existe pas");
    }

    if (querySnapshot.size > 1) {
      throw new InneditError(
        'user/multiple-with-same-uid',
        "Problème de configuration, l'utilisateur n'est pas unique",
      );
    }

    const document = querySnapshot.docs[0];

    return {
      id: document.id,
      ...(document.data() as UserType),
    };
  }

  public async watchByUid(
    uid: string,
    next: (
      snapshot?: DocumentType<UserType>,
      metadata?: FirebaseFirestore.SnapshotMetadata,
    ) => void,
  ): Promise<FirebaseFirestore.Unsubscribe> {
    const constraints = [
      where('deleted', '==', false),
      where('uid', '==', uid),
    ];

    const q = query(this.getCollectionRef(), ...constraints);

    const querySnapshot = await getDocs(q);

    if (0 === querySnapshot.size) {
      throw new InneditError('user/not-exists', "L'utilisateur n'existe pas");
    }

    if (querySnapshot.size > 1) {
      throw new Error(
        "Problème de configuration, l'utilisateur n'est pas unique",
      );
    }

    const { id } = querySnapshot.docs[0];

    const ref = doc<UserType>(this.getCollectionRef(), id);

    return onSnapshot(ref, {
      next: snapshot => {
        if (!snapshot || !snapshot.exists() || snapshot.get('deleted')) {
          return next(undefined);
        }

        const document = {
          id,
          ...(snapshot.data() as UserType),
        };

        return next(document, snapshot.metadata);
      },
    });
  }

  static onChange(next: (user: FirebaseAuth.User | null) => void): void {
    onAuthStateChanged(auth, next);
  }

  static get(): FirebaseAuth.Auth {
    return auth;
  }

  static sendSignInLinkToEmail(email: string, url: string): Promise<void> {
    const actionCodeSettings = {
      url,
      handleCodeInApp: true,
    };

    window.localStorage.setItem('emailForSignIn', email);

    return sendSignInLinkToEmail(auth, email, actionCodeSettings);
  }

  static signInWithEmailLink(href: string): void {
    if (isSignInWithEmailLink(auth, href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      const email = window.localStorage.getItem('emailForSignIn');
      if (email) {
        // The client SDK will parse the code from the link for you.
        signInWithEmailLink(auth, email, window.location.href)
          .then(result => {
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn');
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser

            return true;
          })
          .catch(error => {
            console.error('error', error);
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
          });
      }
    }
  }

  static signInAnonymously(): Promise<FirebaseAuth.UserCredential> {
    return signInAnonymously(auth);
  }

  static async signInWithEmailAndPassword(
    email: string,
    password: string,
  ): Promise<FirebaseAuth.UserCredential> {
    return signInWithEmailAndPassword(auth, email, password);
  }

  static signInWithPhoneNumber(
    phoneNumber: string,
  ): Promise<FirebaseAuth.ConfirmationResult> {
    if ('undefined' === typeof window || !(window as any).recaptchaVerifier) {
      throw new Error("le captcha n'existe pas");
    }

    const appVerifier = (window as any).recaptchaVerifier;

    return signInWithPhoneNumber(auth, phoneNumber, appVerifier);
  }

  static signOut(): Promise<void> {
    return auth.signOut();
  }

  static async signInWithAuthCode(
    email: string,
    code: string,
  ): Promise<boolean> {
    const token = await User.verifyAuthCode(email, code);

    if (!token) {
      return false;
    }

    const user = await signInWithCustomToken(auth, token);

    return Boolean(user);
  }

  static existsByEmail(email: string): Promise<boolean> {
    const func = httpsCallable(functions, 'userExistsByEmail');

    return func({ email }).then(result => result.data as boolean);
  }

  static existsByPhone(phone: string): Promise<boolean> {
    const func = httpsCallable(functions, 'userExistsByPhone');

    return func({ phone }).then(result => result.data as boolean);
  }

  static verifyAuthCode(
    email: string,
    code: string,
  ): Promise<string | undefined> {
    const func = httpsCallable(functions, 'userAuthVerify');

    return func({ code, email }).then(
      result => result.data as string | undefined,
    );
  }
}

export default User;
