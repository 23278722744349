import { DocumentType, PriceType } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent } from 'react';

import Button from '~/components/Button';
import Price from '~/components/Price';
import IconEdit from '~/icons/Edit';

export interface ListItemProps {
  doc: DocumentType<PriceType>;
  editPathname: string;
  removeOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
}

const Item: FC<ListItemProps> = ({ editPathname, doc }) => (
  <div className="flex items-center">
    {doc && <Price data={doc} />}
    <div className="flex space-x-1 self-end">
      {editPathname && (
        <Button
          color="secondary"
          iconLeft={IconEdit}
          size="sm"
          to={`${editPathname}${doc.id}/`}
          variant="ghost"
        />
      )}
    </div>
  </div>
);

export default Item;
