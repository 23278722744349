import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React, { FC } from 'react';

import { ActionProps, ActionVariantType } from '~/components/Actions/props';
import Icon from '~/components/Icon';
import font from '~/styles/font';
import { colors } from '~/styles/theme';
import transition from '~/styles/transition';

const getButtonVariantProps = ({
  variant,
}: {
  variant?: ActionVariantType;
}): any => {
  switch (variant) {
    case 'action': {
      return css`
        background: transparent;
        border: none;
        outline: 0;
        color: ${colors.neutral['400']};
        text-align: center;
        text-decoration: none;
        font-weight: 400;
        line-height: 40px;
        font-size: 15px;

        padding: 0 9px !important;

        min-height: 36px !important;
        min-width: 36px !important;
        border-radius: 8px;

        &:hover {
          color: ${colors.primary['900']} !important;
          background: ${colors.neutral['100']};
        }

        svg {
          height: 16px;
          width: 16px;
        }
      `;
    }

    case 'option':
    case 'sub-option': {
      return css`
        color: ${colors.light['600']};
        flex: 1 1 100%;
        flex-direction: row-reverse;
        > div {
          flex: 1 1 0%;
          text-align: left;
        }

        svg {
          height: 18px;
          width: 18px;
        }

        svg + div {
          margin-right: 12px;
        }
        padding: 6px 12px;
        min-height: 40px;

        &:hover {
          color: ${colors.light['300']} !important;
        }
      `;
    }

    case 'sub-action': {
      return css`
        background: transparent;
        border: none;
        outline: 0;
        color: ${colors.neutral['400']};
        text-align: center;
        text-decoration: none;
        font-weight: 400;
        line-height: 25px;
        font-size: 15px;

        padding: 0 !important;

        min-height: 25px !important;
        min-width: 25px !important;
        border-radius: 8px;

        &:hover {
          color: ${colors.primary['900']} !important;
          background: none;
        }

        svg {
          height: 16px;
          width: 16px;
        }
      `;
    }

    case 'tab': {
      return css`
        background: none;
        border: 0;
        color: ${colors.dark[400]};
        float: none;
        font-size: 14px;
        padding: 0px 0.75rem;
        height: 28px;
        min-height: 28px;
        line-height: 28px;
        white-space: nowrap;

        &:hover {
          color: ${colors.primary[500]};
          background: none;
        }

        svg {
          margin-right: 12px;
          width: 16px;
          height: 16px;
        }

        &.is-active {
          color: ${colors.primary[500]};
          background-color: ${colors.neutral[100]};
          // border: 1px solid ${colors.primary[500]};
          border-radius: 14px !important;
          font-weight: 400;
        }
      `;
    }

    default: {
      return css``;
    }
  }
};

const ActionEL = styled.button<{ variant: ActionVariantType }>`
  appearance: none !important;
  border-style: solid;
  // border-width: 1px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: ${font.weight.light};

  transition: background ${transition.timing.base} ${transition.easing.base},
    border-color ${transition.timing.base} ${transition.easing.base},
    color ${transition.timing.base} ${transition.easing.base},
    box-shadow ${transition.timing.base} ${transition.easing.base};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? 'none !important' : 'auto')};

  ${({ variant }) => getButtonVariantProps({ variant })};
`;

const ActionsItem: FC<ActionProps & { variant: ActionVariantType }> =
  function ({
    icon,
    iconClassName,
    iconColor,
    label,
    variant = 'action',
    to,
    ...props
  }) {
    const others: { [key: string]: any } = {};

    if (to) {
      const domain = (url: string) =>
        url.replace('http://', '').replace('https://', '');

      if (domain(to) === to && props.target !== '_blank') {
        others.as = Link;
        others.to = to;
      } else {
        others.as = 'a';
        others.href = to;
      }
    }

    return (
      <ActionEL title={label} variant={variant as any} {...props} {...others}>
        {icon && (
          <Icon className={iconClassName} color={iconColor} value={icon} />
        )}

        {(['option', 'tab'].includes(variant) || !icon) && <div>{label}</div>}
      </ActionEL>
    );
  };

export default ActionsItem;
