import MDEditor from '@uiw/react-md-editor';
import { Box, DataFieldProps } from 'packages/formidable';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '~/components/Button';
import { ReducersProps } from '~/reducers';

export interface DataMarkdownProps
  extends Omit<DataFieldProps, 'componentType'> {
  formName: string;
}

const DataMarkdown: FC<DataMarkdownProps> = function ({ formName, name }) {
  const [show, setShow] = useState<boolean>(false);
  const markdown = useSelector(
    (state: ReducersProps<any>) =>
      state.form[formName].values && state.form[formName].values[name],
  );

  const handleOnClick = () => {
    setShow(s => !s);
  };

  return (
    <Box>
      <Button
        className={show ? 'mb-6' : ''}
        color="neutral"
        onClick={handleOnClick}
        variant="outline"
      >
        {show ? 'Cacher' : 'Afficher'}
      </Button>
      {show && (
        <MDEditor.Markdown
          source={markdown}
          style={{ whiteSpace: 'pre-wrap' }}
        />
      )}
    </Box>
  );
};

export default DataMarkdown;
