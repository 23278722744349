import { DocumentType, MediaType } from '@innedit/innedit-type';
import classnames from 'classnames';
import React, { FC, SyntheticEvent, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import Button from '~/components/Button';
import ViewModal from '~/components/View/Modal';
import IconClipboard from '~/icons/Clipboard';
import IconDelete from '~/icons/Delete';
import IconDownload from '~/icons/Download';
import IconEdit from '~/icons/Edit';
import IconEyeSlash from '~/icons/EyeSlash';
import IconFileImage from '~/icons/FileImage';
import IconFilePdf from '~/icons/FilePdf';
import IconFileVideo from '~/icons/FileVideo';
import { colors } from '~/styles/theme';

const ItemTypes = {
  MEDIA: 'media',
};

const Media: FC<{
  index: number;
  isDragging: boolean;
  media: DocumentType<MediaType>;
  removeOnClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
}> = ({ index, isDragging, media, removeOnClick }) => {
  const src = `${String(process.env.GATSBY_IMAGES_DOMAIN_NAME)}/${
    media.fullPath
  }`;

  const isImage = 'image/' === media.type.slice(0, 6);
  const isSVG = 'image/svg+xml' === media.type;
  const isMP4 = 'video/mp4' === media.type;
  const isPdf = 'application/pdf' === media.type;
  const isVideo = 'video/' === media.type.slice(0, 6);
  let newMedia;

  if (isImage) {
    newMedia = `${String(process.env.GATSBY_IMAGES_DOMAIN_NAME)}/${
      media.fullPath
    }`;
    if (!isSVG) {
      newMedia += 0 === index ? '?w=640&h=640&c=max' : '?w=320&h=320&c=max';
    }
  }

  const handleClipboardOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (navigator && navigator.clipboard && media) {
      navigator.clipboard.writeText(
        `${String(process.env.GATSBY_IMAGES_DOMAIN_NAME)}/${media.fullPath}`,
      );
    }
  };

  const handleDownloadOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if ('undefined' !== typeof window) {
      if (isImage) {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.src = src;
        img.onload = () => {
          // create Canvas

          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          if (ctx) {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            // create a tag
            const a = document.createElement('a');
            a.download = media.label;
            a.href = canvas.toDataURL(media.type);
            a.click();
          }
        };
      } else {
        const a = document.createElement('a');
        a.download = media.label;
        a.href = src;
        a.target = '_blank';
        a.click();
      }
    }
  };

  return (
    <div
      className={classnames(
        {
          isDragging,
        },
        'relative',
      )}
    >
      <figure className="aspect-ratio aspect-ratio--square">
        {isImage && newMedia && (
          <img
            alt="chargement"
            className="aspect-ratio__content"
            src={newMedia}
          />
        )}
        {isImage && !newMedia && (
          <IconFileImage
            className="aspect-ratio__content"
            color={colors.neutral[500]}
            size={60}
          />
        )}
        {isPdf && (
          <IconFilePdf
            className="aspect-ratio__content"
            color={colors.neutral[500]}
            size={60}
          />
        )}
        {isVideo && isMP4 && (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video className="aspect-ratio__content" src={src} />
        )}
        {isVideo && !isMP4 && (
          <IconFileVideo
            className="aspect-ratio__content"
            color={colors.neutral[500]}
            size={60}
          />
        )}
        {media.hidden && (
          <div className="absolute w-full h-full flex items-center justify-center bg-light-300/80">
            <IconEyeSlash />
          </div>
        )}
      </figure>
      <div className="hover-overlay">
        {media && (
          <div className="text-sm text-white-50 text-center">
            <div>{media.type}</div>
            {media.width && media.height && (
              <div>{`${media.width} x ${media?.height} px`}</div>
            )}
            {media.size && (
              <div>{`${Math.round(media.size / 10000) / 100} Mo`}</div>
            )}
          </div>
        )}
        <div className="flex justify-around">
          <Button
            className="w-[16px] h-16px]"
            color="light"
            data-index={index}
            iconLeft={IconClipboard}
            onClick={handleClipboardOnClick}
            variant="link"
          />
          <Button
            className="w-[16px] h-16px]"
            color="light"
            data-index={index}
            iconLeft={IconDownload}
            onClick={handleDownloadOnClick}
            variant="link"
          />
          <Button
            className="w-[16px] h-16px]"
            color="light"
            data-index={index}
            iconLeft={IconEdit}
            to={`/espaces/${media.espaceId}/medias/${media.parentCollectionName}/${media.parentId}/${media.id}/update/`}
            variant="link"
          />
          <Button
            className="w-[16px] h-16px]"
            color="light"
            data-id={media.id}
            iconLeft={IconDelete}
            onClick={removeOnClick}
            variant="link"
          />
        </div>
      </div>
    </div>
  );
};

interface DragMedia {
  index: number;
  id: string;
  type: string;
}

const MediaPosition: FC<{
  changePosition: (oldIndex: number, newIndex: number) => void;
  index: number;
  media: DocumentType<MediaType>;
  removeItem: (id: string) => void;
}> = ({ changePosition, removeItem, index, media }) => {
  const { t } = useTranslation();
  const [openDelete, setOpenDelete] = useState<boolean>();

  const ref = useRef<HTMLLIElement>(null);

  const handleRemoveOnClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setOpenDelete(true);
  };

  const handleDeleteConfirmOnClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    removeItem(media.id);
  };

  const handleCloseDelete = (e?: SyntheticEvent<HTMLButtonElement>) => {
    e?.preventDefault();

    setOpenDelete(false);
  };

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.MEDIA,
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
    }),
    drop(item: DragMedia) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      changePosition(dragIndex, hoverIndex);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    item: { index, id: media.id, type: ItemTypes.MEDIA },
    type: ItemTypes.MEDIA,
  });

  drag(drop(ref));

  return (
    <li ref={ref} className={isOver ? 'isOver' : ''}>
      {openDelete && (
        <ViewModal
          closeOnClick={handleCloseDelete}
          maxWidth="500px"
          title="Suppression"
        >
          <p>{t('medias.delete.question.label')}</p>
          <div className="flex space-x-3 items-center justify-center mt-3">
            <Button
              color="neutral"
              onClick={handleCloseDelete}
              variant="outline"
            >
              {t('medias.delete.no.label')}
            </Button>
            <Button
              color="danger"
              onClick={handleDeleteConfirmOnClick}
              variant="solid"
            >
              {t('medias.delete.yes.label')}
            </Button>
          </div>
        </ViewModal>
      )}
      <Media
        index={index}
        isDragging={isDragging}
        media={media}
        removeOnClick={handleRemoveOnClick}
      />
    </li>
  );
};

export default MediaPosition;
