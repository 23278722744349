import { DocumentType, FeatureType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import { FeatureData } from 'packages/innedit';
import React, {
  ReactElement,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';

import { DataFeaturesListProps } from '~/datas/props';

import HOCGroup from '../../../components/Group/HOC';
import Item from './Item';

const DataFeaturesList = ({
  collectionName,
  customFields,
  display,
  editPathname,
  espaceId,
  label,
  modalDatas,
  modalFormName,
  modalTitle,
  nothingLabel,
  params,
  showLibelle = true,
  title,
  type = 'parent',
}: DataFeaturesListProps): ReactElement | null => {
  const [docs, setDocs] = useState<DocumentType<FeatureType>[]>();
  const [timeOuts, setTimeOuts] = useState<{ [id: string]: NodeJS.Timeout }>(
    {},
  );

  useEffect(() => {
    let isMounted = true;
    let unsub: (() => void) | undefined;

    const featureData = new FeatureData({
      espaceId,
      parentCollectionName:
        !espaceId || 'sub' === type ? params?.collectionName : undefined,
      parentId: !espaceId || 'sub' === type ? params?.docId : undefined,
    });

    if ('sub' === type || params?.docId) {
      unsub = featureData.watch(
        newDocs => {
          if (isMounted) {
            setDocs(newDocs);
          }
        },
        {
          wheres: {
            parent: espaceId && 'parent' === type ? params?.docId : undefined,
          },
        },
      );
    } else {
      setDocs([]);
    }

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, collectionName, params]);

  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const model = new FeatureData({
      espaceId,
      parentCollectionName:
        !espaceId || 'sub' === type ? params?.collectionName : undefined,
      parentId: !espaceId || 'sub' === type ? params?.docId : undefined,
    });

    const data: { [key: string]: any } = {
      label: '',
    };

    if (customFields) {
      (Array.isArray(customFields) ? customFields : [customFields]).forEach(
        field => {
          data[(field.name as string) ?? 'label'] = '';
        },
      );
    }

    const newDoc: { [key: string]: any } = model.initialize({
      ...data,
      parent: espaceId && 'parent' === type ? params?.docId : undefined,
    });

    if (espaceId && 'parent' === type && !params?.docId) {
      throw new Error(
        'le docId est obligatoire pour associer cette feature a un parent',
      );
    }

    model.create(newDoc).catch(error => {
      throw new Error(`Features List : handleAdd() : ${error.message}`);
    });
  };

  const handleOnChange = (
    event: SyntheticEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const index = event.currentTarget.getAttribute('data-index');
    const attr = event.currentTarget.getAttribute('data-name');
    const { value } = event.currentTarget;

    if (docs && index && attr) {
      const item = docs[parseInt(index, 10)];

      if (timeOuts && timeOuts[item.id]) {
        clearTimeout(timeOuts[item.id]);
      }

      const timeoutId = setTimeout(() => {
        const featureData = new FeatureData({
          espaceId,
          parentCollectionName:
            !espaceId || 'sub' === type ? params?.collectionName : undefined,
          parentId: !espaceId || 'sub' === type ? params?.docId : undefined,
        });

        featureData.update(item.id, {
          [attr]: value,
          updatedAt: dayjs().toISOString(),
        } as any);
      }, 1000);

      setTimeOuts(oldTimeOuts => ({ ...oldTimeOuts, [item.id]: timeoutId }));
    }
  };

  const handleRemoveOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const index = event.currentTarget.getAttribute('data-index');
    if (docs && index) {
      const featureData = new FeatureData({
        espaceId,
        parentCollectionName:
          !espaceId || 'sub' === type ? params?.collectionName : undefined,
        parentId: !espaceId || 'sub' === type ? params?.docId : undefined,
      });

      featureData.delete(docs[parseInt(index, 10)].id);
    }
  };

  const handleChangePosition = (oldIndex: number, newIndex: number) => {
    if (docs && newIndex - oldIndex !== 1) {
      let datetime = dayjs().valueOf();
      const newDatetime = docs[newIndex].datetime;

      if (0 < newIndex) {
        const beforeDatetime = docs[newIndex - 1].datetime;
        const diff = Math.floor((beforeDatetime - newDatetime) / 2);
        datetime = newDatetime + diff;
      }
      if (datetime === docs[newIndex].datetime) {
        console.error(
          "le nouveau datetime est le même que celui de l'ancienne position",
        );
      }

      const featureData = new FeatureData({
        espaceId,
        parentCollectionName:
          !espaceId || 'sub' === type ? params?.collectionName : undefined,
        parentId: !espaceId || 'sub' === type ? params?.docId : undefined,
      });

      featureData.update(docs[oldIndex].id, {
        datetime,
        updatedAt: dayjs().toISOString(),
      });
    }
  };

  if ('parent' === type && (!params || !params.docId)) {
    return null;
  }

  return (
    <HOCGroup addOnClick={handleAddOnClick} display={display} title={title}>
      {!docs && <p className="m-0">Chargement en cours</p>}
      {docs && 0 === docs.length ? (
        <p className="m-0">{nothingLabel || 'Aucun élément'}</p>
      ) : (
        <div className="mb-6">
          {docs?.map((doc, index) => (
            <Item
              key={doc.id || index}
              collectionName={collectionName}
              customFields={customFields}
              doc={doc}
              editPathname={editPathname}
              espaceId={espaceId}
              handleChangePosition={handleChangePosition}
              index={index}
              label={label}
              modalDatas={modalDatas}
              modalFormName={modalFormName}
              modalTitle={modalTitle}
              onChange={handleOnChange}
              parentCollectionName={params?.collectionName}
              parentId={params?.docId}
              removeOnClick={handleRemoveOnClick}
              showLibelle={showLibelle}
              type={type}
            />
          ))}
        </div>
      )}
    </HOCGroup>
  );
};

export default DataFeaturesList;
