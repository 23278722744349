import React, { FC, ReactNode } from 'react';

import IconBack from '~/icons/Back';
import IconClose from '~/icons/Close';
import IconDelete from '~/icons/Delete';
import IconNext from '~/icons/Next';
import IconPlus from '~/icons/Plus';

const Icon: FC<{
  className?: string;
  color?: string;
  size?: number;
  value: ReactNode;
}> = ({ className, color, size, value }) => {
  if (typeof React.Component === typeof value) {
    const IconCmp: any = value as React.ReactElement;

    return <IconCmp className={className} color={color} size={size} />;
  }

  switch (value) {
    case 'add': {
      return <IconPlus className={className} color={color} size={size} />;
    }

    case 'close':
      return <IconClose className={className} color={color} size={size} />;

    case 'back': {
      return <IconBack className={className} color={color} size={size} />;
    }

    case 'next': {
      return <IconNext className={className} color={color} size={size} />;
    }

    case 'remove': {
      return <IconDelete className={className} color={color} size={size} />;
    }

    default:
  }

  return <>{value}</>;
};
export default Icon;
