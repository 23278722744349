import { OrderType } from '@innedit/innedit-type';
import { DataProps } from 'packages/formidable';
import React, { FC, SyntheticEvent } from 'react';

import HOCGroup from '~/components/Group/HOC';
import PaymentItem from '~/datas/Paiement/Manager/PaymentItem';

export interface DataPaiementManagerProps
  extends Omit<DataProps, 'componentType'> {
  docId: string;
  espaceId: string;
  formName: string;
}

const DataPaiementManager: FC<DataPaiementManagerProps> = ({
  display,
  docId,
  espaceId,
  formName,
  params,
}) => {
  const item: Partial<OrderType> = params && params[formName];

  const leftToPayAmount = (item?.totalAmount ?? 0) - (item?.paymentAmount ?? 0);

  const handleCreateOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    // TODO, il serait plus facile de mettre à jour automatiquement les paiements lorsqu'il existe un paiement non encore validé
    fetch(
      `${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/espaces/${espaceId}/payment_intents/upsert`,
      {
        body: JSON.stringify({ commande: docId }),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      },
    )
      .then(res => res.json())
      .then(({ code, data, error, message }) => {
        if (error) {
          console.error(code, message);
        } else {
          // setClientSecret(data.client_secret);
        }

        return !error;
      })
      .catch(() => {
        throw new Error('Problème avec stripe');
      });
  };

  return (
    <HOCGroup
      addOnClick={leftToPayAmount !== 0 ? handleCreateOnClick : undefined}
      display={display}
      title="Paiements"
    >
      {item?.paymentIds?.map((paymentId, index) => (
        <PaymentItem
          key={paymentId}
          espaceId={espaceId}
          index={index}
          paymentId={paymentId}
        />
      ))}
    </HOCGroup>
  );
};

export default DataPaiementManager;
