import { DocumentType, SchemaType } from '@innedit/innedit-type';
import { SchemaData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { DataField, DataFieldProps } from '../../packages/formidable';

export type DataSchemasSelectProps = Omit<DataFieldProps, 'componentType'>;

const DataSchemasSelect: FC<DataSchemasSelectProps> = ({ name, ...props }) => {
  const [schemas, setSchemas] = useState<DocumentType<SchemaType>[]>();
  useEffect(() => {
    let isMounted = true;
    const schemaData = new SchemaData({});

    schemaData.watch(
      newDocs => {
        if (isMounted) {
          setSchemas(newDocs);
        }
      },
      {
        wheres: {
          hidden: false,
        },
      },
    );

    return () => {
      isMounted = false;
    };
  }, []);

  if (!schemas || 0 === schemas.length) {
    return null;
  }

  return (
    <DataField
      {...props}
      componentType="select"
      name={name}
      options={schemas.map(schema => ({
        label: schema.label,
        value: schema.id,
      }))}
    />
  );
};

export default DataSchemasSelect;
