import { DataSection } from 'packages/formidable';
import React, { FC, SyntheticEvent, useState } from 'react';
import { WrappedFieldProps } from 'redux-form';

import Button from '~/components/Button';
import Input from '~/components/Input';
import { DataAttributesProps } from '~/datas/props';
import IconArrowUp from '~/icons/ArrowUp';

const AttributesRender: FC<
  WrappedFieldProps & Omit<DataAttributesProps, 'name'>
> = ({ formName, input, params }) => {
  const [text, setText] = useState<string>('');

  const handleOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { value } = event.currentTarget;

    setText(value);
  };

  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (text) {
      let newValue = input.value;
      if (!newValue) {
        newValue = {};
      }
      newValue[text] = undefined;
      input.onChange(newValue);
      setText('');
    }
  };

  return (
    <div>
      <div className="flex">
        <Input
          className="rounded-r-none"
          onChange={handleOnChange}
          type="text"
          value={text}
        />
        <Button
          className="rounded-l-none"
          color="neutral"
          iconLeft={IconArrowUp}
          onClick={handleAddOnClick}
          variant="outline"
        />
      </div>
      <div className="value mt-6 flex flex-col">
        {input.value && Object.keys(input.value).length > 0 && (
          <DataSection
            datas={Object.keys(input.value).map(key => ({
              attributeType: 'textarea',
              componentType: 'attribute',
              name: key,
            }))}
            formName={formName}
            name={input.name.split('.').reverse()[0]}
            params={params}
          />
        )}
      </div>
    </div>
  );
};

export default AttributesRender;
