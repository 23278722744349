import { DocumentType, TaskType } from '@innedit/innedit-type';
import { DataField, DataFieldSelectProps } from 'packages/formidable';
import { TaskData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

export interface DataTasksSelectProps extends DataFieldSelectProps {
  className?: string;
  espaceId: string;
  formName: string;
  id?: string;
  label?: string;
}
const DataTasksSelect: FC<
  Omit<DataTasksSelectProps, 'options' | 'componentType'>
> = function ({
  className,
  espaceId,
  formName,
  name,
  id,
  label,
  labelShow,
  ...props
}) {
  const [options, setOptions] = useState<DocumentType<TaskType>[]>([]);

  useEffect(() => {
    const pageData = new TaskData({ espaceId });
    pageData
      .find()
      .then(docs => {
        setOptions(docs);

        return true;
      })
      .catch(e => {
        throw new Error(`loadTasks : ${e.message}`);
      });
  }, [espaceId, id]);

  if (!options || 0 === options.length) {
    return null;
  }

  return (
    <DataField
      {...props}
      className={className}
      componentType="select"
      formName={formName}
      label={label}
      labelShow={labelShow}
      name={name}
      options={options.map(item => ({
        label: item.label,
        value: item.id,
      }))}
    />
  );
};

export default DataTasksSelect;
