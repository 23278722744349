import classnames from 'classnames';
import objectHash from 'object-hash';
import React, { FC } from 'react';

import { ActionProps, ActionVariantType } from '~/components/Actions/props';

import Item from './Item';

const Group: FC<{ variant: ActionVariantType }> = ({ children, variant }) => (
  <div
    className={classnames('flex flex-1', {
      'hover:bg-neutral-50 rounded-[8px]': variant !== 'option',
    })}
  >
    {children}
  </div>
);

export interface MenuType {
  left?: (ActionProps | ActionProps[])[];
  right?: (ActionProps | ActionProps[])[];
}

const Actions: FC<{
  className?: string;
  items?: (ActionProps | ActionProps[])[];
  variant?: ActionVariantType;
}> = ({ className = 'flex', items, variant = 'action' }) => {
  if (!items || 0 === items.length) {
    return null;
  }

  return (
    <ul className={className}>
      {items.map((item, index) => (
        <li
          key={objectHash(item)}
          className={classnames('flex flex-nowrap', {
            'border-t border-dark-900': 'option' === variant && index > 0,
          })}
        >
          {Array.isArray(item) ? (
            <Group variant={variant}>
              {item.map((subItem, gIndex) => (
                <Item
                  key={objectHash({ ...item, ...subItem })}
                  {...subItem}
                  className={classnames(subItem.className, {
                    'border-l border-dark-900':
                      gIndex > 0 && 'option' === variant,
                    'rounded-l-none': gIndex > 0,
                    'rounded-r-none border-r-0': item.length - 1 > gIndex,
                  })}
                  variant={'option' === variant ? 'sub-option' : variant}
                />
              ))}
            </Group>
          ) : (
            <Item {...item} variant={variant} />
          )}
        </li>
      ))}
    </ul>
  );
};

export default Actions;
