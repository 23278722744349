import styled from '@emotion/styled';
import {
  CollectionType,
  ContentType,
  DocumentType,
} from '@innedit/innedit-type';
import { CollectionData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import ButtonGroup from '../../../components/Button/Group';
import IconArrowDown from '../../../icons/ArrowDown';
import IconArrowUp from '../../../icons/ArrowUp';
import IconDelete from '../../../icons/Delete';
import IconEdit from '../../../icons/Edit';
import IconGripHorizontal from '../../../icons/GripHorizontal';
import IconKeyboard from '../../../icons/Keyboard';
import { spacing } from '../../../styles/theme';

export const ItemEL = styled.div`
  position: relative;

  & + & {
    margin-top: ${spacing[3]};
  }

  // .actions {
  //   display: none;
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   left: 0;
  //   bottom: 0;
  // }

  &:hover {
    .actions {
      display: flex;
    }
  }

  h1 {
    font-weight: bold;
    margin: 0 0 ${spacing[6]} 0;
  }

  h2 {
    font-weight: bold;
    margin: 0 0 ${spacing[6]} 0;
  }

  h3,
  h4,
  h5 {
    font-weight: bold;
    margin: 0 0 ${spacing[6]} 0;
  }

  p + h2,
  ul + h2 {
    margin-top: ${spacing[8]} !important;
  }

  * + h3,
  * + h4,
  * + h5 {
    margin-top: ${spacing[6]} !important;
  }

  p + p,
  p + ul,
  ul + p,
  ul + ul {
    margin-top: ${spacing[3]};
  }

  ul {
    list-style: disc;
    margin-left: ${spacing[6]};
    text-align: justify;
  }
`;

const Item: FC<{
  deleteOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  document: DocumentType<ContentType>;
  downOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  editOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  index: number;
  upOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
}> = ({
  deleteOnClick,
  document,
  downOnClick,
  editOnClick,
  index,
  upOnClick,
}) => {
  const { t } = useTranslation();
  const [collection, setCollection] = useState<DocumentType<CollectionType>>();

  useEffect(() => {
    let isMounted = true;
    if (document.collectionId) {
      const collectionData = new CollectionData({
        espaceId: document.espaceId,
      });

      const id = document.collectionId.slice('collections/'.length);
      if (id) {
        collectionData
          .findById(id)
          .then(newCollection => {
            if (isMounted) {
              setCollection(newCollection);
            }

            return isMounted;
          })
          .catch(console.error);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [document]);

  const nb = document.text?.replace(/\n/g, ' ').trim().split(' ').length;

  return (
    <ItemEL className="flex flex-col border p-1 rounded bg-light-300">
      <div className="flex space-x-2 mx-1.5">
        {document.label && (
          <strong className="truncate text-ellipsis overflow-hidden">
            {document.label}
          </strong>
        )}
        {document.text && (
          <p className="truncate text-ellipsis overflow-hidden">
            {document.text.slice(0, 100)}
          </p>
        )}

        {document.collectionId && (
          <p className="truncate text-ellipsis overflow-hidden">
            Collection : {collection?.label ?? document.collectionId}
          </p>
        )}
      </div>

      {(document.label || document.text || document.collectionId) && (
        <hr className="my-1" />
      )}

      <div className="flex justify-between text-neutral-700">
        <div className="flex space-x-3 text-xs ml-2 items-center">
          {'collection' === document.kind && <IconGripHorizontal size={10} />}
          {'form' === document.kind && <IconKeyboard size={10} />}
          {document.medias && document.medias.length > 0 && (
            <div>{t('content.medias', { count: document.medias.length })}</div>
          )}
          {document.hyperlinks && document.hyperlinks.length > 0 && (
            <div>
              {t('content.hyperlinks', { count: document.hyperlinks.length })}
            </div>
          )}
          {nb && <div>{t('content.words', { count: nb })}</div>}
        </div>
        <div className="actions justify-center items-center">
          <ButtonGroup className=" rounded">
            <Button
              color="neutral"
              data-id={document.id}
              iconLeft={IconEdit}
              onClick={editOnClick}
              size="xs"
              variant="outline"
            />
            <Button
              color="neutral"
              data-index={index}
              iconLeft={IconArrowUp}
              onClick={upOnClick}
              size="xs"
              variant="outline"
            />
            <Button
              color="neutral"
              data-index={index}
              iconLeft={IconArrowDown}
              onClick={downOnClick}
              size="xs"
              variant="outline"
            />
            <Button
              color="neutral"
              data-index={index}
              iconLeft={IconDelete}
              onClick={deleteOnClick}
              size="xs"
              variant="outline"
            />
          </ButtonGroup>
        </div>
      </div>
    </ItemEL>
  );
};

export default Item;
