import { DataProps, Group } from 'packages/formidable';
import React, { FC, SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';

import Button from '~/components/Button';

export interface DataProfessionalsKYCProps
  extends Omit<DataProps, 'componentType'> {
  formName: string;
  espaceId: string;
  docId: string;
}

const DataProfessionalsKYC: FC<DataProfessionalsKYCProps> = ({
  display,
  docId,
  espaceId,
  formName,
}) => {
  const { stripeAccountId, stripeAccountVerified } = useSelector(
    (globalState: any) => globalState.form[formName].values,
  );

  const handleCreateAccountOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    let url = `${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/espaces/${espaceId}`;
    url += `/accounts/create?professionalId=${docId}`;
    fetch(url).catch(error => {
      console.error(error.message);
    });
  };

  const handleUpdateAccountOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    let url = `${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/espaces/${espaceId}`;
    url += `/accounts/update?professionalId=${docId}`;
    fetch(url).catch(error => {
      console.error(error.message);
    });
  };

  return (
    <Group display={display} title="Stripe KYC">
      {!stripeAccountId && (
        <Button
          color="neutral"
          onClick={handleCreateAccountOnClick}
          size="sm"
          variant="groove"
        >
          Créer un compte connecté
        </Button>
      )}

      {stripeAccountId && stripeAccountVerified && (
        <div className="text-center text-light-800 flex space-x-1">
          <span>Compte connecté créé</span>
          <span className="text-success-500">et vérifié</span>
        </div>
      )}

      {stripeAccountId && !stripeAccountVerified && (
        <div className="text-center text-light-800 flex space-x-1 items-center justify-between">
          <div>
            <span>Compte connecté créé</span>{' '}
            <span className="text-danger-500">et non vérifié</span>
          </div>
          <Button
            color="neutral"
            onClick={handleUpdateAccountOnClick}
            size="sm"
            variant="groove"
          >
            Vérifier
          </Button>
        </div>
      )}
    </Group>
  );
};

export default DataProfessionalsKYC;
