import { DataType, DocumentType, ProduitType } from '@innedit/innedit-type';
import objectHash from 'object-hash';
import { Box, Data, DataProps } from 'packages/formidable';
import { GroupBody, GroupTitle } from 'packages/formidable/components/Group';
import { ProduitData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import Button from '~/components/Button';
import Media from '~/components/Media';

export interface DataProduitParentProps
  extends Omit<DataProps, 'componentType'> {
  datas?: DataType[];
  espaceId: string;
  parentId: string;
}

const DataProduitParent: FC<DataProduitParentProps> = ({
  datas,
  espaceId,
  parentId,
  title,
  titleProps,
  ...props
}) => {
  const [produit, setProduit] = useState<DocumentType<ProduitType>>();

  useEffect(() => {
    let isMounted = true;
    if (parentId) {
      const produitData = new ProduitData({
        espaceId,
      });

      produitData.watchById(parentId, newDocument => {
        if (isMounted) {
          setProduit(newDocument);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [parentId]);

  return (
    <Box>
      {produit && (
        <>
          {title && <GroupTitle {...titleProps}>{title}</GroupTitle>}
          <GroupBody className="bg-light-500">
            <div className="grid grid-cols-3 gap-3">
              {produit.medias && (
                <div>
                  <Media
                    className="rounded"
                    media={produit.medias[0]}
                    options="w=65&h=65"
                  />
                </div>
              )}

              <div className="col-span-2">
                <h2 className="text-md">{produit.label}</h2>
                <Button
                  to={`/espaces/${espaceId}/produits/${parentId}/update/`}
                  variant="link"
                >
                  Retour au produit
                </Button>
              </div>
            </div>
            {datas &&
              datas.length > 0 &&
              datas.map(data => (
                <Data
                  key={objectHash(data)}
                  {...props}
                  {...data}
                  params={{
                    ...props.params,
                    parentId,
                    parentCollectionName: 'produits',
                  }}
                />
              ))}
          </GroupBody>
        </>
      )}
    </Box>
  );
};

export default DataProduitParent;
