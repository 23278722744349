import { EspaceData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { DataGoogleSearchConsoleProps } from '~/datas/props';

import HOCGroup from '../../components/Group/HOC';

const DataGoogleSearchConsole: FC<DataGoogleSearchConsoleProps> = ({
  display,
  field,
  formName,
  title = 'Google',
}) => {
  const [stats, setStats] = useState<string>();

  const { values } = useSelector(
    (globalState: any) => globalState.form[formName],
  );

  const url = values && values[field];

  useEffect(() => {
    let isMounted = true;
    if (url) {
      const espaceData = new EspaceData();
      espaceData
        .search(`site:${url}`)
        .then((data: any) => {
          if (isMounted) {
            setStats(data.stats);
          }

          return isMounted;
        })
        .catch(toast.error);
    }

    return () => {
      isMounted = false;
    };
  }, [url]);

  return (
    <HOCGroup display={display} title={title}>
      {stats}
    </HOCGroup>
  );
};

export default DataGoogleSearchConsole;
