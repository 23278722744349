import React, { FC, SyntheticEvent } from 'react';
import { WrappedFieldArrayProps } from 'redux-form';

import HOCGroup from '~/components/Group/HOC';
import { DataMenuProps } from '~/datas/props';

import DataMenuItem from './Item';

const DataMenuRender: FC<
  WrappedFieldArrayProps & Omit<DataMenuProps, 'name'>
> = ({ attributesName, datas, display, fields, formName, params, title }) => {
  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    fields.push({
      href: undefined,
      label: undefined,
      pageId: undefined,
      type: 'page',
    });
  };

  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const idx = event.currentTarget.getAttribute('data-index');
    if (null !== idx) {
      fields.remove(parseInt(idx, 10));
    }
  };

  const handleDownOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );
    if (fields && i >= 0 && i < fields.length - 1) {
      // On inverse le index et le index+1
      fields.swap(i + 1, i);
    }
  };

  const handleUpOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );

    if (fields && i >= 1 && i <= fields.length - 1) {
      // On inverse le index et le index+1
      fields.swap(i - 1, i);
    }
  };

  return (
    <HOCGroup
      addOnClick={handleAddOnClick}
      attributesName={attributesName}
      display={display}
      formName={formName}
      params={params}
      title={title}
    >
      {fields.length > 0 && (
        <div className="flex flex-col space-y-2">
          {fields.map((field, index) => (
            <DataMenuItem
              key={field}
              deleteOnClick={handleDeleteOnClick}
              downOnClick={handleDownOnClick}
              formName={formName}
              index={index}
              name={field}
              params={params}
              upOnClick={handleUpOnClick}
            />
          ))}
        </div>
      )}
      {0 === fields.length && <p>Aucun lien</p>}
    </HOCGroup>
  );
};

export default DataMenuRender;
