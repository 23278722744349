import classnames from 'classnames';
import { FeatureData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import { ButtonSizeType } from '~/components/Button/props';
import Input from '~/components/Input';
import IconCheck from '~/icons/Check';
import IconClose from '~/icons/Close';
import IconPlus from '~/icons/Plus';

interface MultiSelectCreateProps {
  buttonClassName?: string;
  buttonIcon?: FC;
  buttonSize?: ButtonSizeType;
  espaceId: string;
  parent?: string;
}
const MultiSelectCreate: FC<MultiSelectCreateProps> = function ({
  buttonClassName,
  buttonIcon = IconPlus,
  buttonSize = 'md',
  espaceId,
  parent,
}) {
  const [libelle, setLibelle] = useState<string>('');
  const [showCreating, setShowCreating] = useState<boolean>(false);

  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (libelle) {
      // TODO créer la nouvelle caractéristique
      const featureData = new FeatureData({ espaceId });
      toast.promise(
        featureData.create({
          parent,
          label: libelle,
        }),
        {
          error: 'Erreur lors de la création',
          pending: 'Création en cours',
          success: 'Création de la caractéristique réussie',
        },
      );
      setLibelle('');
      setShowCreating(false);
    }
  };

  const handleOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setLibelle(value);
  };

  const handleToggleOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setShowCreating(s => !s);
  };

  return (
    <div>
      <Button
        className={classnames(
          'mx-auto absolute',
          { 'top-[2px] right-[8px]': !buttonClassName },
          buttonClassName,
        )}
        iconLeft={showCreating ? IconClose : buttonIcon}
        onClick={handleToggleOnClick}
        size={buttonSize}
        variant="sub-action"
      />
      {showCreating && (
        <div className=" mb-3">
          <div className="flex">
            <Input
              className="rounded-r-none h-[34px] text-[14px] leading-[28px]"
              onChange={handleOnChange}
              placeholder="Libellé de la nouvelle caractéristique"
              value={libelle}
            />
            <Button
              className="mx-auto rounded-l-none border-l-0 w-[34px]"
              color="neutral"
              iconLeft={IconCheck}
              onClick={handleAddOnClick}
              size="xs"
              variant="outline"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelectCreate;
