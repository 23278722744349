import { CollectionType, DocumentType } from '@innedit/innedit-type';
import { CollectionData, DocumentData, ModelData } from 'packages/innedit';
import React, { useEffect, useState, VFC } from 'react';
import { toast } from 'react-toastify';

import { DataField, DataFieldProps } from '../../../packages/formidable';

export interface DataDocumentsSelectProps
  extends Omit<DataFieldProps, 'componentType'> {
  collectionId: string;
  espaceId: string;
}

const DataDocumentsSelect: VFC<DataDocumentsSelectProps> = ({
  collectionId,
  espaceId,
  name,
  ...props
}) => {
  const [collection, setCollection] = useState<DocumentType<CollectionType>>();

  const [model, setModel] = useState<ModelData<any>>();
  const [options, setOptions] = useState<DocumentType<any>[]>();

  const collectionData = new CollectionData();

  useEffect(() => {
    const id = collectionId.slice('collections/'.length);
    if (id) {
      collectionData
        .findById(id)
        .then(document => {
          if (document) {
            const newModel = new DocumentData({
              ...document,
              parentCollectionName: 'collections',
              parentId: id,
            });

            setCollection(document);
            setModel(newModel);
          }

          return true;
        })
        .catch(toast.error);
    }
  }, [collectionId]);

  useEffect(() => {
    let isMounted = true;
    if (model) {
      model
        .find()
        .then(documents => {
          if (isMounted) {
            setOptions(documents);
          }

          return false;
        })
        .catch(toast.error);
    }

    return () => {
      isMounted = false;
    };
  }, [model]);

  if (!options) {
    return null;
  }

  // TODO voir pour vérifier l'affichage du label par rapport au labelFields
  return (
    <DataField
      {...props}
      componentType="select"
      name={name}
      options={options.map(option => ({
        label:
          collection &&
          collection.labelFields &&
          collection.labelFields.length > 0
            ? option[collection.labelFields[0]]
            : option.name,
        value: option.id,
      }))}
    />
  );
};

export default DataDocumentsSelect;
