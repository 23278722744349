import { DocumentType, ThoughtType } from '@innedit/innedit-type';
import { mergeWith } from 'lodash';

import Model, { ModelProps } from '../Model';

export interface ModelThoughtProps extends ModelProps<ThoughtType> {
  parentField?: string;
}

class Thought extends Model<ThoughtType> {
  public parentField: string;

  constructor(props: Omit<ModelThoughtProps, 'collectionName'>) {
    super({
      ...props,
      canDoSearch: true,
      collectionName: 'thoughts',
      labelFields: ['createdAt'],
      orderDirection: props.orderDirection || 'asc',
      orderField: props.orderField || 'createdAt',
      queryBy: 'text',
    });

    this.parentField = props.parentField || 'thoughts';

    // on ajout les conditions au wheres
    mergeWith(this.wheres, {
      parentField: this.parentField,
    });
  }

  public async create(
    data: Partial<ThoughtType>,
    id?: string,
  ): Promise<DocumentType<ThoughtType>> {
    return super.create({
      ...data,
      parentCollectionName: this.parentCollectionName,
      parentField: this.parentField,
      parentId: this.parentId,
    });
  }

  public initialize(data?: Partial<ThoughtType>): Partial<ThoughtType> {
    return super.initialize({
      parentCollectionName: this.parentCollectionName,
      parentField: this.parentField,
      parentId: this.parentId,
      ...data,
    });
  }
}

export default Thought;
