import { ExtractType } from '@innedit/innedit-type';
import Model, { ModelProps } from 'packages/innedit/datas/Model';

class Extract extends Model<ExtractType> {
  constructor(props?: Omit<ModelProps<ExtractType>, 'collectionName'>) {
    super({
      ...props,
      canDoSearch: true,
      collectionName: 'extracts',
      labelFields: ['title'],
      orderDirection: props?.orderDirection || 'desc',
      orderField: props?.orderField || 'createdAt',
      queryBy: 'title, keywords, description, siteName',
    });
  }
}

export default Extract;
