import { PaymentItemType } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent } from 'react';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import ButtonGroup from '~/components/Button/Group';
import IconCheckCircle from '~/icons/CheckCircle';
import IconEdit from '~/icons/Edit';

import ProfessionalsItem from './Item';

interface ProfessionalsProps {
  espaceId: string;
  index: number;
  item: PaymentItemType;
  paymentId: string;
}

const Professionals: FC<ProfessionalsProps> = ({
  espaceId,
  index,
  paymentId,
  item: { price, professionals, quantity, refundedAmount, transferredAt },
}) => {
  const handleTransfertOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const url = `espaces/${espaceId}/transfers/create?paiement=${paymentId}&item=${index}`;

    fetch(`${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/${url}`)
      .then(r => r.json())
      .catch(toast.error);
  };
  if (professionals) {
    const hasConnected = Object.keys(professionals).reduce(
      (acc, key) => acc || Boolean(professionals[key].destination),
      false,
    );

    return (
      <div className="mt-1.5 bg-light-500 p-2 rounded text-xs">
        {!transferredAt && (
          <ButtonGroup className="float-right space-x-2">
            <Button
              color="neutral"
              iconLeft={IconEdit}
              size="sm"
              variant="link"
            />
            {hasConnected && (
              <Button
                color="neutral"
                iconLeft={IconCheckCircle}
                onClick={handleTransfertOnClick}
                size="sm"
                variant="link"
              />
            )}
          </ButtonGroup>
        )}

        <h2>Professionnels</h2>
        {Object.keys(professionals).length > 0 && (
          <div className="flex flex-col space-y-1 mt-2">
            {Object.keys(professionals).map(key => (
              <ProfessionalsItem
                key={key}
                itemAmount={price * quantity}
                professional={professionals[key]}
                refundedAmount={refundedAmount}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default Professionals;
