import { DataFieldInputProps } from 'packages/formidable/components/Data/props';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { WrappedFieldProps } from 'redux-form';

import Input from '~/components/Input';

const FieldInput: FC<WrappedFieldProps & DataFieldInputProps> = ({
  autoComplete,
  description,
  descriptionParams,
  disabled,
  fieldProps,
  id,
  input,
  innerElement,
  placeholder,
  mask,
  max,
  maxLength,
  meta: { error, touched },
  min,
  step,
  type = 'text',
}) => {
  const { t } = useTranslation();

  const newFieldProps = {
    ...fieldProps,
    disabled,
    id,
    max,
    maxLength,
    min,
    step,
    placeholder: placeholder ? t(placeholder) : placeholder,
    status: touched && error ? 'error' : undefined,
    type: mask ? 'text' : type,
  };

  const newDescription = description
    ? t(description, descriptionParams)
    : description;

  return (
    <>
      {mask ? (
        <InputMask mask={mask} {...input}>
          <>
            {(inputProps: any) => (
              <Input
                {...inputProps}
                {...newFieldProps}
                autoComplete={autoComplete}
              />
            )}
          </>
        </InputMask>
      ) : (
        <Input {...input} {...newFieldProps} autoComplete={autoComplete} />
      )}
      {newDescription && <label htmlFor={id}>{newDescription}</label>}

      {innerElement}
    </>
  );
};

export default FieldInput;
