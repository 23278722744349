import { UserType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import { UserData } from 'packages/innedit';
import { Dispatch } from 'redux';
import slug from 'slug';

import {
  USER_CREATE,
  USER_IS_LOGOUT,
  USER_LOAD_ERROR,
  USER_LOAD_REQUEST,
  USER_LOAD_SUCCESS,
  USER_REMOVE,
  USER_UNSUBSCRIBE,
  USER_UPDATE,
} from '~/constants/actionTypes2';

import findByEspaceId from './espace/findById';
// import findByIdsSchemas from './schema/findByIds';

dayjs.locale('fr');

slug.defaults.mode = 'rfc3986';

export function load({ uid }: { uid: string }, espaceId?: string) {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: USER_LOAD_REQUEST,
    });

    const userData = new UserData();
    userData
      .watchByUid(uid, (user, metadata) => {
        if (!user) {
          dispatch({
            error: "L'utilisateur n'existe pas ou a été supprimé",
            type: USER_LOAD_ERROR,
          });
        } else {
          dispatch({
            user,
            type: USER_LOAD_SUCCESS,
          });

          if (espaceId && metadata && !metadata.hasPendingWrites) {
            // 1. On charge le espace
            dispatch(findByEspaceId(espaceId));

            // 2. On charge l'espace
            const newEspaceId = espaceId || user.espace;
            const { espaces } = user;
            if (
              newEspaceId &&
              ('admin' === user.type ||
                (espaces && espaces.includes(newEspaceId)))
            ) {
              if (
                'admin' === user.type ||
                (espaces && espaces.includes(newEspaceId))
              ) {
                // TODO valider le fait que l'utilisateur peut consulter l'espace
              }
            }

            // // 3. On charge les schemas
            // if (user.schemas && user.schemas.length > 0) {
            //   dispatch(findByIdsSchemas(user.schemas));
            // }
          }
        }
      })
      .then(unsubscribe =>
        dispatch({
          unsubscribe,
          type: USER_UNSUBSCRIBE,
        }),
      )
      .catch(error => {
        console.error(error);
        dispatch({
          error: "L'utilisateur n'existe pas ou a été supprimé",
          type: USER_LOAD_ERROR,
        });
      });
  };
}

export function isLogout(): any {
  return {
    type: USER_IS_LOGOUT,
  };
}

export function create(item: UserType): any {
  const userData = new UserData();

  return {
    payload: userData.create(item),
    type: USER_CREATE,
  };
}

export function update(key: string, item: UserType): any {
  const userData = new UserData();

  return {
    payload: userData.update(key, item),
    type: USER_UPDATE,
  };
}

export function remove(id: string): any {
  const userData = new UserData();

  return {
    payload: userData.delete(id),
    type: USER_REMOVE,
  };
}
