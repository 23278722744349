import styled from '@emotion/styled';
import { DataFieldProps } from 'packages/formidable/components/Data/props';
import FormidableContext from 'packages/formidable/FormidableContext';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { WrappedFieldProps } from 'redux-form';

const CustomActionSC = styled.div``;
const FieldMessageSC = styled.div<{ color?: string }>``;
const FieldTemplateSC = styled.div``;

const FieldTemplate: FC<WrappedFieldProps & DataFieldProps> = ({
  children,
  customAction,
  customActionProps,
  templateProps,
  message,
  meta: { error, touched, warning },
}) => {
  const { t } = useTranslation();
  const { sc } = useContext(FormidableContext);

  return (
    <>
      <FieldTemplateSC as={sc && sc.fieldTemplate} {...templateProps}>
        {children}

        {customAction && (
          <CustomActionSC as={sc && sc.customTop} {...customActionProps}>
            {customAction}
          </CustomActionSC>
        )}
      </FieldTemplateSC>
      {touched &&
        ((error && (
          <FieldMessageSC as={sc && sc.fieldMessage} color="error">
            {t ? t(error) : error}
          </FieldMessageSC>
        )) ||
          (warning && (
            <FieldMessageSC as={sc && sc.fieldMessage} color="warning">
              {t ? t(warning) : warning}
            </FieldMessageSC>
          )))}
      {message && (
        <FieldMessageSC as={sc && sc.fieldMessage}>
          {t ? t(message) : message}
        </FieldMessageSC>
      )}
    </>
  );
};

export default FieldTemplate;
