import { DocumentType, ProduitType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import compact from 'lodash/compact';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../Button';

dayjs.locale('fr');

export const calcStart = (startAt: string): string =>
  dayjs(startAt).format('HH:mm');

export const calcEnd = (
  startTime: string,
  duration?: string,
): string | null => {
  if (!duration) {
    return null;
  }

  const [startH, startM] = startTime.split(':');
  const [durationH, durationM] = duration.split(':');

  return `${Number(startH) + Number(durationH)}:${String(
    Number(startM) + Number(durationM),
  ).padStart(2, '0')}`;
};

interface SlotProps {
  data: DocumentType<ProduitType>;
}

const Item: FC<SlotProps> = ({ data }) => {
  const { t } = useTranslation();

  const {
    variantValues,

    qtyAvailable,
    reference,
  } = data;

  if (!variantValues) {
    return <div>Ce n&apos;est pas une déclinaison</div>;
  }
  const { slot } = variantValues;

  if (!slot) {
    return <div>Ce n&apos;est pas une déclinaison avec un créneau</div>;
  }
  const { duration, startDate, startTime } = slot;

  const info = (
    <Button
      to={`/espaces/${data.espaceId}/produits/${data.id}/update/`}
      variant="clear"
    >
      <div className="flex-1 flex justify-between">
        {startTime && (
          <span>
            {compact([startTime, calcEnd(startTime, duration)]).join(' - ')}
          </span>
        )}
        {qtyAvailable ? (
          <span className="text-light-800 italic">
            {t('slot.places', { count: qtyAvailable })}
          </span>
        ) : (
          'Éditer'
        )}
      </div>
    </Button>
  );

  return (
    <div className="flex flex-col flex-1">
      <div className="text-left flex space-x-3 items-center">
        <strong>
          {startDate ? dayjs(startDate).format('dddd D MMM YYYY') : '<date>'}
        </strong>
        <span className="text-xs text-neutral-500">{reference}</span>
      </div>
      {info}
    </div>
  );
};

export default Item;
