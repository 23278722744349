import { DataFieldType } from '@innedit/innedit-type';
import { compact } from 'lodash';
import { DataSection } from 'packages/formidable';
import { CollectionData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { change } from 'redux-form';

import Button from '~/components/Button';
import HOCGroup from '~/components/Group/HOC';
import { DataContentFieldsProps } from '~/datas/props';
import IconArrowDown from '~/icons/ArrowDown';
import IconArrowUp from '~/icons/ArrowUp';
import IconDelete from '~/icons/Delete';
import IconRedo from '~/icons/Redo';

const DataContentFields: FC<DataContentFieldsProps> = function ({
  attributesName,
  display,
  espaceId,
  formName,
  name,
  params,
  title,
}) {
  const [fields, setFields] = useState<DataFieldType[]>([]);

  const dispatch = useDispatch();
  const item = params && params[formName];
  const itemFields = item && item[name];

  const loadFieldsFromCollection = async (
    collectionId: string,
  ): Promise<DataFieldType[]> => {
    const collectionData = new CollectionData({ espaceId });
    const snapshot = await collectionData.findById(collectionId);
    const newFields = [...fields];
    snapshot?.fields?.forEach(field => {
      if (newFields.find(f => f.name === field.name) === undefined) {
        newFields.push(field);
      }
    });

    return newFields;
  };

  useEffect(() => {
    let isMounted = true;
    if (itemFields) {
      setFields(itemFields);
    } else if (item.collectionId) {
      // on charge les fields de la collection
      if (item.collectionId.startsWith('collections/')) {
        loadFieldsFromCollection(item.collectionId.slice(12))
          .then(newFields => {
            if (isMounted) {
              setFields(newFields);
              dispatch(change(formName, name, newFields));
            }

            return isMounted;
          })
          .catch(toast.error);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [item.collectionId, itemFields]);

  const handleReinitializeOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    if (item?.collectionId) {
      if (item.collectionId.startsWith('collections/')) {
        loadFieldsFromCollection(item.collectionId.slice(12))
          .then(newFields => {
            dispatch(change(formName, name, newFields));

            return setFields(newFields);
          })
          .catch(toast.error);
      }
    }
  };

  const handleUpOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );

    if (undefined !== i && i > 0) {
      // On inverse le index et le index-1
      const newFields = [...fields];
      [newFields[i - 1], newFields[i]] = [newFields[i], newFields[i - 1]];
      setFields(newFields);
      dispatch(change(formName, name, newFields));
    }
  };

  const handleDownOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );
    if (fields && i < fields.length - 1) {
      // On inverse le index et le index+1
      const newFields = [...fields];
      [newFields[i + 1], newFields[i]] = [newFields[i], newFields[i + 1]];
      setFields(newFields);
      dispatch(change(formName, name, newFields));
    }
  };

  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const i = event.currentTarget.getAttribute('data-index');
    if (fields && null !== i) {
      const newFields = [...fields];

      newFields.splice(parseInt(i, 10), 1);
      dispatch(change(formName, name, newFields));
    }
  };

  return (
    <HOCGroup
      attributesName={attributesName}
      customInfos={
        <>
          <Button
            iconLeft={IconRedo}
            onClick={handleReinitializeOnClick}
            variant="sub-action"
          />
        </>
      }
      display={display}
      formName={formName}
      params={params}
      title={title}
    >
      {fields.length > 0 &&
        fields.map((field, index) => (
          <DataSection
            key={`fields_${field.name}`}
            datas={[
              {
                actions: compact([
                  index > 0 && {
                    datas: {
                      'data-index': index,
                    },
                    iconLeft: IconArrowUp,
                    onClick: handleUpOnClick,
                    variant: 'link',
                  },
                  index < fields.length - 1 && {
                    iconLeft: IconArrowDown,
                    onClick: handleDownOnClick,
                    variant: 'link',
                  },
                  {
                    color: 'danger',
                    datas: {
                      'data-index': index,
                    },
                    iconLeft: IconDelete,
                    onClick: handleDeleteOnClick,
                    variant: 'link',
                  },
                ]),
                attributesName: 'attributes',
                componentType: 'group',
                datas: [
                  {
                    cols: [1, 3],
                    componentType: 'grid',
                    datas: [
                      {
                        componentType: 'input',
                        label: 'Label',
                        labelShow: true,
                        name: 'label',
                        wrapperProps: {
                          col: ['full', 2],
                        },
                      },
                      {
                        componentType: 'input',
                        description: 'Afficher le label ?',
                        fieldProps: {
                          className: 'reverse',
                        },
                        label: 'Label',
                        labelShow: false,
                        name: 'labelShow',
                        templateProps: {
                          className: 'flex-row-reverse',
                        },
                        type: 'checkbox',
                        wrapperProps: {
                          className: 'items-end justify-center',
                        },
                      },
                    ],
                  },
                  {
                    componentType: 'textarea',
                    label: 'Description',
                    labelShow: true,
                    name: 'description',
                  },
                ],
                display: 'inside',
                title: `#${field.name}`,
              },
            ]}
            formName={formName}
            name={`fields[${index}]`}
            params={params}
          />
        ))}
    </HOCGroup>
  );
};
export default DataContentFields;
