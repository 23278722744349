import React, { FC } from 'react';

const ContentsDefault: FC<{
  addOnBlur: (text: string) => void;
  size: number;
}> = ({ addOnBlur, size }) => {
  // const handleOnBlur = ({ value }: { value?: string }) => {
  //   if (value) {
  //     addOnBlur(value);
  //   }
  // };

  if (size > 0) {
    return null;
  }

  return <p>Aucun contenu</p>;
  // return (
  //   <DataField
  //     componentType="textarea"
  //     handleOnBlur={handleOnBlur}
  //     labelShow={false}
  //     name="text"
  //   />
  // );
};

export default ContentsDefault;
