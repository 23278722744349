import {
  DocumentType,
  OrderProductType,
  OrderType,
} from '@innedit/innedit-type';
import dayjs from 'dayjs';
import FirebaseFirestore, {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import capitalize from 'lodash/capitalize';

import { auth, firestore } from '../../../config/firebase';
import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';
import UserData from '../../User';

class Order extends ModelEspace<OrderType> {
  constructor(props: Omit<ModelEspaceProps<OrderType>, 'collectionName'>) {
    super({
      addButtonLabel: 'Créer un bon de commande',
      canDoSearch: true,
      collectionName: 'commandes',
      queryBy: [
        'contactName',
        'contactAddress',
        'contactZip',
        'contactCity',
        'contactEmail',
        'contactPhone',
        'contactCountry',
        'products',
      ].join(', '),
      tabs: [
        {
          label: 'Tous les bons',
          orderDirection: 'desc',
          orderField: 'createdAt',
          pathname: `/espaces/${props.espaceId}/commandes/`,
          wheres: {
            archived: false,
          },
        },
        {
          label: 'En attente',
          orderDirection: 'desc',
          orderField: 'createdAt',
          pathname: `/espaces/${props.espaceId}/commandes/en-attente/`,
          wheres: {
            archived: false,
            status: 'waiting',
          },
        },
        {
          label: 'Archivés',
          orderDirection: 'desc',
          orderField: 'createdAt',
          pathname: `/espaces/${props.espaceId}/commandes/archived/`,
          wheres: {
            archived: true,
          },
        },
        {
          label: 'Brouillon',
          orderDirection: 'desc',
          orderField: 'createdAt',
          pathname: `/espaces/${props.espaceId}/commandes/brouillon/`,
          wheres: {
            archived: false,
            status: {
              operator: '==',
              value: 'draft',
            },
          },
        },
      ],
      ...props,
    });
  }

  public async confirmByUser(
    commandeId: string,
    values: Partial<OrderType>,
  ): Promise<boolean> {
    // On envoi un lien de connexion pour authentifier l'utilisateur
    if (auth.currentUser?.isAnonymous && values.contactEmail) {
      await UserData.sendSignInLinkToEmail(
        values.contactEmail,
        `${String(process.env.GATSBY_URL_DOMAIN)}/login`,
      );
    }

    // Avant de mettre à jour, il faut vérifier la disponibilité des produits de la commande
    const ref = doc(this.getCollectionRef(), commandeId);
    const documentSnapshot = await getDoc(ref);
    if (!documentSnapshot || !documentSnapshot.exists()) {
      throw new Error("La commande n'existe pas");
    }
    const data = documentSnapshot.data();

    if (!data) {
      throw new Error('La commande ne contient aucune donnée');
    }

    if (data.deleted) {
      throw new Error('La commande est supprimée');
    }

    if ('paid' === data.status) {
      throw new Error('Cette commande est déjà payée');
    }

    const verification = await Order.verificationProduits(
      values.products || data.products,
    );

    if (verification) {
      // On confirme la commande
      const date = dayjs();
      const newData: Partial<OrderType> = {
        ...values,
        confirmedAt: date.toISOString(),
        status: 'confirmed',
        updatedAt: date.toISOString(),
      };
      await updateDoc(doc(this.getCollectionRef(), commandeId), newData as any);
    }

    return verification;
  }

  public async create(
    data: Partial<OrderType>,
  ): Promise<DocumentType<OrderType>> {
    const user = auth.currentUser;
    if (!user) {
      throw new Error(
        "L'utilisateur doit être connecté pour enregistrer un document",
      );
    }

    if (!data.products || 0 === data.products.length) {
      throw new Error('Impossible de créer une commande sans produit');
    }

    const verification = await Order.verificationProduits(data.products);

    if (!verification) {
      throw new Error("Au moins un produit n'est pas disponible");
    }

    const { contactId } = data;

    // On recherche si l'utilisateur a déjà une commande avec le status 'cart'
    const constraints = [
      where('status', '==', 'waiting'),
      where('deleted', '==', false),
      where('espaceId', '==', this.espaceId),
    ];

    if (contactId) {
      constraints.push(where('contactId', '==', contactId));
    }

    const q = query(this.getCollectionRef(), ...constraints);
    const querySnapshot = await getDocs(q);

    let tmp;
    let ref;
    if (querySnapshot.size > 0) {
      if (querySnapshot.size > 1) {
        throw new Error(
          "Ce n'est pas normal, il ne devrait pas avoir plusieurs commandes",
        );
      }
      // On récupére la référence de la commande
      [tmp] = querySnapshot.docs;
      ref = tmp.ref;
    } else {
      ref = doc(this.getCollectionRef());
    }

    // // 1.0 On récupère le numero de la future commande
    // const reference = this.getNextReference();

    const newData = this.clean({ createdByUser: user.uid, ...data }, true);
    if (tmp && tmp.get('paymentIds')) {
      newData.paymentIds = tmp.get('paymentIds');
    }
    await setDoc<OrderType>(ref, this.initialize(newData) as OrderType);
    const documentSnapshot = await getDoc<OrderType>(ref);

    return {
      id: documentSnapshot.id,
      ...(documentSnapshot.data() as OrderType),
    };
  }

  initialize(data?: Partial<OrderType>): Partial<OrderType> {
    // const token = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 20)()
    //   .split('')
    //   .reduce(
    //     (acc, value, index) =>
    //       `${acc}${index > 0 && 0 === index % 4 ? '-' : ''}${value}`,
    //     '',
    //   );
    // const deliveryDate = dayjs().add(3, 'weeks');

    return this.clean(data);
  }

  public clean(
    values?: Partial<OrderType>,
    validate?: boolean,
  ): Partial<OrderType> {
    const contactName = values?.contactName
      ? values?.contactName.split(' ').map(capitalize).join(' ')
      : undefined;

    return super.clean(
      {
        ...values,
        contactName,
        currency: values?.currency ?? 'EUR',
        deliveryToContactAddress: values?.deliveryToContactAddress ?? true,
        discountAmount: values?.discountAmount ?? 0,
        packingAmount: values?.packingAmount ?? 0,
        paymentAmount: values?.paymentAmount ?? 0,
        paymentAmountReceived: values?.paymentAmountReceived ?? 0,
        paymentAmountRefunded: values?.paymentAmountRefunded ?? 0,
        status: values?.status ?? 'draft',
        totalAmount: values?.totalAmount ?? 0,
      },
      validate,
    );
  }

  public async getNextReference(): Promise<string> {
    const debut = dayjs().format('YYWWE');
    const fin = String(parseInt(debut, 10) + 1);

    const constraints = [
      where('reference', '>', debut),
      where('reference', '<', fin),
      orderBy('reference'),
    ];

    const q = query(this.getCollectionRef(), ...constraints);
    const querySnapshot = await getDocs(q);

    let reference = `${debut}0001`;
    if (querySnapshot.size > 0) {
      reference = querySnapshot.docs[querySnapshot.size - 1].get('reference');
      reference =
        debut + `0000${parseInt(reference.substr(-3), 10) + 1}`.slice(-4);
    }

    return reference;
  }

  static async findByCheckoutSessionId(
    sessionId: string,
  ): Promise<DocumentType<OrderType>> {
    const constraints = [where('checkoutSessionId', '==', sessionId)];
    const q = query(collection(firestore, 'commandes'), ...constraints);

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty || querySnapshot.size !== 1) {
      throw new Error('Il y a une erreur');
    }

    return {
      id: querySnapshot.docs[0].id,
      ...(querySnapshot.docs[0].data() as OrderType),
    };
  }

  static watchByTokenAndId(
    token: string,
    id: string,
    callback: (snapshot: DocumentType<OrderType>) => void,
    error: (e: FirebaseFirestore.FirestoreError) => void,
  ): () => void {
    return onSnapshot(
      doc(firestore, 'commandes', id),
      snapshot => {
        if (!snapshot.exists || snapshot.get('token') !== token) {
          throw new Error("la commande n'existe pas");
        }

        return callback({
          id: snapshot.id,
          ...(snapshot.data() as OrderType),
        });
      },
      error,
    );
  }

  static calculateProduitsAmount(produits: OrderProductType[]): number {
    if (!produits || 0 === produits.length) {
      return 0;
    }

    return produits.reduce((cumul, produit) => {
      const { price, quantity } = produit;

      return cumul + quantity * (price || 0);
    }, 0);
  }

  static async verificationProduits(
    items: OrderProductType[],
  ): Promise<boolean> {
    const promiseProduits: Promise<{
      amount: number;
      deleted: boolean;
      hasInventory: boolean;
      isAvailable: boolean;
      id: string;
      price: number;
      quantity: number;
      qtyAvailable: number;
      qtyReserved: number;
    }>[] = [];

    items.forEach(({ id, price, quantity }) => {
      if (id) {
        promiseProduits.push(
          new Promise((resolve, reject) =>
            getDoc(doc(firestore, 'produits', id))
              .then(d =>
                resolve({
                  id,
                  price,
                  quantity,
                  amount: price,
                  deleted: d.get('deleted') || false,
                  hasInventory: d.get('hasInventory') || false,
                  isAvailable: d.get('isAvailable') || false,
                  qtyAvailable: d.get('qtyAvailable') || 0,
                  qtyReserved: d.get('qtyReserved') || 0,
                }),
              )
              .catch(reject),
          ),
        );
      }
    });

    const produits = await Promise.all(promiseProduits);

    const unavailableProduits = [];
    produits.forEach(
      ({
        deleted,
        hasInventory,
        id,
        isAvailable,
        quantity,
        qtyAvailable,
        qtyReserved,
      }) => {
        if (
          deleted ||
          !isAvailable ||
          (hasInventory && quantity > qtyAvailable - qtyReserved)
        ) {
          // ce produit n'est plus disponible
          unavailableProduits.push(id);
        }
      },
    );

    return 0 === unavailableProduits.length;
  }
}

export default Order;
