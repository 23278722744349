import React, { VFC } from 'react';
import { WrappedFieldArrayProps } from 'redux-form';

import HOCGroup from '~/components/Group/HOC';
import { DataInquiryMessagesProps } from '~/datas/props';

import Produit from './Item';

const DataInquiryMessagesRender: VFC<
  WrappedFieldArrayProps & DataInquiryMessagesProps
> = ({ display, espaceId, fields, label }) => (
  <HOCGroup display={display} title={label}>
    {fields.length > 0 ? (
      <div>
        <div className="flex flex-col -my-6">
          {fields.map((name, fieldIndex, allfields) => (
            <Produit
              key={name}
              data={allfields.get(fieldIndex)}
              espaceId={espaceId}
              index={fieldIndex}
              nbMessages={allfields.length}
            />
          ))}
        </div>
      </div>
    ) : (
      <p>Aucun message</p>
    )}
  </HOCGroup>
);

export default DataInquiryMessagesRender;
