import dayjs from 'dayjs';
import { DataProps } from 'packages/formidable';
import { AnalyticsData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import HOCGroup from '~/components/Group/HOC';
import IconLoading from '~/icons/Loading';
import IconRedo from '~/icons/Redo';

export interface DataAnalyticsProps extends Omit<DataProps, 'componentType'> {
  channelId: string;
}

const DataAnalytics: FC<DataAnalyticsProps> = ({ channelId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [nbPages, setNbPages] = useState<number>();
  const [nbSessions, setNbSessions] = useState<number>();
  const [nbUsers, setNbUsers] = useState<number>();
  const [start, setStart] = useState<string>(
    dayjs().subtract(1, 'M').format('YYYY-MM-DD'),
  );
  const [end, setEnd] = useState<string>(dayjs().format('YYYY-MM-DD'));

  const generate = (after: string, before: string, isMounted: boolean) => {
    if (setIsLoading) {
      setIsLoading(true);
      setError(undefined);
      AnalyticsData.kpis({
        channelId,
        end: dayjs(before).add(1, 'd').format('YYYY-MM-DD'),
        start: after,
      })
        .then(result => {
          if (isMounted) {
            setNbPages(result.nbPages);
            setNbSessions(result.nbSessions);
            setNbUsers(result.nbUsers);
            setIsLoading(false);
          }

          return true;
        })
        .catch(e => {
          toast.error(e);

          setError(e.message);
        });
    }
  };

  useEffect(() => {
    let isMounted = true;
    generate(start, end, isMounted);

    return () => {
      isMounted = false;
    };
  }, []);

  const handleOnClick = async (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    generate(start, end, true);
  };

  const handleStartOnChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setStart(value);
    generate(value, end, true);
  };

  const handleEndOnChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setEnd(value);
    generate(start, value, true);
  };

  return (
    <HOCGroup
      customInfos={
        <Button
          iconLeft={IconRedo}
          onClick={handleOnClick}
          variant="sub-action"
        />
      }
      display="content"
      title="Statistiques"
    >
      <div className="flex justify-center space-x-3 border-b pb-2 mb-2 px-6 -mx-6 -mt-6 pt-2">
        <input
          className="border rounded px-1"
          onChange={handleStartOnChange}
          type="date"
          value={start}
        />
        <span>-</span>
        <input
          className="border rounded px-1"
          onChange={handleEndOnChange}
          type="date"
          value={end}
        />
      </div>

      {isLoading && !error && (
        <div className="flex items-center justify-center h-[53px] space-x-3">
          <IconLoading /> <span>Chargement en cours des statistiques ...</span>
        </div>
      )}
      {error && (
        <div className="flex items-center justify-center h-[53px]">
          <span className="text-danger-500">{error}</span>
        </div>
      )}
      {!isLoading && !error && (
        <div className="flex justify-around">
          {nbUsers !== undefined && (
            <div className="flex flex-col">
              <span>Utilisateurs</span>
              <span className="text-xl font-medium">{nbUsers}</span>
            </div>
          )}
          {nbSessions !== undefined && (
            <div className="flex flex-col">
              <span>Visites</span>
              <span className="text-xl font-medium">{nbSessions}</span>
            </div>
          )}
          {nbPages !== undefined && (
            <div className="flex flex-col">
              <span>Pages</span>
              <span className="text-xl font-medium">{nbPages}</span>
            </div>
          )}
        </div>
      )}
    </HOCGroup>
  );
};

export default DataAnalytics;
