// import { DataSection } from 'packages/formidable';
import React, { FC, SyntheticEvent } from 'react';
import { Field } from 'redux-form';

// import apparence from './apparence.json';
// import colors from './colors.json';
// import dimensions from './dimensions.json';
// import display from './display.json';
// import position from './position.json';
// import spacing from './spacing.json';
// import text from './text.json';
import ViewModal from '~/components/View/Modal';
import AttributesRender from '~/datas/Attributes/Render';

interface ModalProps {
  closeOnClick: (event?: SyntheticEvent<HTMLButtonElement>) => void;
  formName: string;
  name: string;
  params?: { [key: string]: any };
  title: string;
}

const Modal: FC<ModalProps> = function ({
  closeOnClick,
  formName,
  name,
  params,
  title,
  ...props
}) {
  // const datas = [
  //   {
  //     componentType: 'flex',
  //     datas: [
  //       {
  //         componentType: 'box',
  //         datas: [
  //           {
  //             componentType: 'group',
  //             datas: display,
  //             display: 'inside',
  //             title: 'Disposition',
  //           },
  //         ],
  //       },
  //       {
  //         componentType: 'group',
  //         datas: spacing,
  //         display: 'inside',
  //         title: 'Espacement',
  //       },
  //       {
  //         componentType: 'group',
  //         datas: dimensions,
  //         display: 'inside',
  //         title: 'Dimensions',
  //       },
  //       {
  //         componentType: 'box',
  //         datas: [
  {
    /*          { */
  }
  {
    /*            componentType: 'group', */
  }
  //             datas: colors,
  //             display: 'inside',
  //             title: 'Couleurs',
  //           },
  //         ],
  //       },
  //       {
  //         componentType: 'group',
  //         datas: apparence,
  //         display: 'inside',
  //         title: 'Apparence',
  //       },
  //       {
  //         componentType: 'group',
  //         datas: text,
  //         display: 'inside',
  //         title: 'Text',
  //       },
  //       {
  //         componentType: 'group',
  //         datas: position,
  //         display: 'inside',
  //         title: 'Position',
  //       },
  //     ],
  //   },
  // ];

  return (
    <ViewModal
      buttons={{
        right: [{ label: 'Fermer', onClick: closeOnClick }],
      }}
      closeOnClick={closeOnClick}
      title={title}
    >
      <Field
        component={AttributesRender}
        name={name}
        {...props}
        formName={formName}
        params={params}
      />
    </ViewModal>
  );
};

export default Modal;
// const propsAreEqual = (prevProps: ModalProps, nextProps: ModalProps): boolean =>
//   prevProps.formName === nextProps.formName &&
//   prevProps.name === nextProps.name;
//
// export default memo(Modal, propsAreEqual);
