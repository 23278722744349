import styled from '@emotion/styled';
import { DataType } from '@innedit/innedit-type';
import hash from 'object-hash';
import {
  DataConditionProps,
  DataFieldProps,
  DataProps,
  DataSectionProps,
  DataTabsProps,
  DataWithChildrenProps,
  TabsPageInfoProps,
  TabType,
} from 'packages/formidable/components/Data/props';
import TabsBar from 'packages/formidable/components/Data/Tabs/Bar';
import FormidableContext from 'packages/formidable/FormidableContext';
import replaceTestParams from 'packages/formidable/utils/replaceTestParams';
import verifyCondition from 'packages/formidable/utils/verifyCondition';
import React, {
  FC,
  ReactElement,
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
  VFC,
} from 'react';
import { connect, DefaultRootState, useSelector } from 'react-redux';
import { FormSection, getFormValues } from 'redux-form';

import Button from '~/components/Button';
import Modal from '~/datas/Attributes/Modal';
import IconFileCode from '~/icons/FileCode';

import Box from '../Box';
import Flex from '../Flex';
import Grid from '../Grid';
// eslint-disable-next-line import/no-cycle
import GroupComponent from '../Group';
import DataField from './Field';
import DataArray from './FieldArray';

const Data: FC<DataType> = ({ children, ...props }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOpenOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpenModal(o => !o);
  };

  const handleCloseOnClick = (event?: SyntheticEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    setOpenModal(false);
  };

  const { componentType, wrapper, wrapperFunc } = props;

  let datas;
  let name;
  const { extendData } = useContext(FormidableContext);

  let Component: ReactElement | null = null;

  if (extendData) {
    Component = extendData(props);
  }

  if (!Component) {
    switch (componentType) {
      case 'array': {
        name = (props as DataFieldProps).name;
        if (!name) {
          Component = (
            <div>{`${componentType} : erreur de paramètre : name obligatoire`}</div>
          );
        }
        Component = <DataArray {...props} name={name} />;

        break;
      }

      case 'box':
      case 'flex':
      case 'group':
      case 'grid': {
        datas = (props as DataWithChildrenProps).datas;
        if (!datas) {
          Component = (
            <div>{`${componentType} : erreur de paramètre : datas obligatoire`}</div>
          );
        } else {
          Component = <DataWithChildren {...props} datas={datas} />;
        }

        break;
      }

      case 'condition': {
        const { test } = props as unknown as DataConditionProps;
        datas = (props as unknown as DataConditionProps).datas;
        if (!datas) {
          Component = (
            <div>{`${componentType} : erreur de paramètre : datas obligatoire`}</div>
          );
        } else if (!props.formName) {
          Component = (
            <div>{`${componentType} : erreur de paramètre : formName obligatoire`}</div>
          );
        } else if (!test) {
          Component = (
            <div>{`${componentType} : erreur de paramètre : test obligatoire`}</div>
          );
        } else {
          Component = <DataCondition {...props} datas={datas} test={test} />;
        }

        break;
      }

      case 'select': {
        name = (props as DataFieldProps).name;
        if (!name) {
          Component = (
            <div>{`${componentType} : erreur de paramètre : name obligatoire`}</div>
          );
        } else {
          Component = (
            <DataField {...props} componentType="select" name={name} />
          );
        }

        break;
      }

      case 'section': {
        datas = (props as unknown as DataSectionProps).datas;
        if (!props.name) {
          Component = (
            <div>{`${componentType} : erreur de paramètre : name obligatoire`}</div>
          );
        } else if (!datas) {
          Component = (
            <div>{`${componentType} : erreur de paramètre : datas obligatoire`}</div>
          );
        } else {
          Component = (
            <DataSection {...props} datas={datas} name={props.name} />
          );
        }

        break;
      }

      case 'tabs': {
        datas = (props as DataTabsProps).datas;
        if (!datas) {
          Component = (
            <div>{`${componentType} : erreur de paramètre : datas obligatoire`}</div>
          );
        } else {
          Component = <DataTabs {...(props as DataTabsProps)} datas={datas} />;
        }

        break;
      }

      case 'textarea': {
        name = (props as DataFieldProps).name;
        if (!name) {
          Component = (
            <div>{`${componentType} : erreur de paramètre : name obligatoire`}</div>
          );
        } else {
          Component = (
            <DataField {...props} componentType="textarea" name={name} />
          );
        }

        break;
      }

      case 'hidden':
      case 'input': {
        name = (props as DataFieldProps).name;

        if (!name) {
          Component = (
            <div>{`${componentType} : erreur de paramètre : name obligatoire`}</div>
          );
        } else {
          Component = (
            <DataField {...props} componentType={componentType} name={name} />
          );
        }

        break;
      }

      default: {
        Component = (
          <div>{`${componentType} : ce type de composant n'est pas pris en charge`}</div>
        );
      }
    }
  }

  if (
    props.formName &&
    ['input', 'textarea'].includes(componentType) &&
    props.attributesName
  ) {
    Component = (
      <div className="flex flex-col space-y-3">
        <div className="flex has-attributes">
          {openModal && (
            <Modal
              closeOnClick={handleCloseOnClick}
              formName={props.formName}
              name={props.attributesName}
              params={props.params}
              title={props.attributesName}
            />
          )}
          {Component}
          <Button
            className="rounded-none rounded-r"
            color="neutral"
            iconLeft={IconFileCode}
            onClick={handleOpenOnClick}
            variant="outline"
          />
        </div>
        <Data
          componentType="attributes"
          formName={props.formName}
          name={props.attributesName}
          params={props.params}
          type="show"
        />
      </div>
    );
  }
  const Wrapper = wrapper;

  return Wrapper && props.formName && props.position ? (
    <Wrapper
      componentType={componentType}
      formName={props.formName}
      name={name}
      position={props.position}
      {...wrapperFunc}
    >
      {Component}
    </Wrapper>
  ) : (
    Component
  );
};

export default Data;

export { DataArray };

const DefaultCmp = styled.div``;

export const DataWithChildren: FC<DataWithChildrenProps> = ({
  componentType,
  datas,
  formName,
  formValues,
  mode,
  params,
  position,
  wrapper,
  wrapperFunc,
  ...props
}) => {
  let Cmp;

  switch (componentType) {
    case 'box': {
      Cmp = Box;
      break;
    }

    case 'flex': {
      Cmp = Flex;
      break;
    }

    case 'group': {
      Cmp = GroupComponent;
      break;
    }

    case 'grid': {
      Cmp = Grid;
      break;
    }
    default: {
      Cmp = DefaultCmp;
    }
  }

  const newDatas = datas && !Array.isArray(datas) ? [datas] : datas;

  return (
    <Cmp {...props} formName={formName} params={params}>
      {newDatas &&
        newDatas.length > 0 &&
        newDatas.map((data, index) => (
          <Data
            key={hash({
              ...data,
              index,
              customAction: null,
              customBottom: null,
              customInfos: null,
              customTop: null,
              datas: null,
              params: null,
            })}
            {...data}
            formName={formName}
            formValues={formValues}
            mode={mode}
            params={params}
            position={`${position ? `${position}.` : ''}datas[${index}]`}
            wrapper={wrapper}
            wrapperFunc={wrapperFunc}
          />
        ))}
    </Cmp>
  );
};

export const DataSection: FC<DataSectionProps> = ({
  className,
  datas,
  name,
  formName,
  formValues,
  mode,
  params,
  position,
  wrapper,
  wrapperFunc,
  ...props
}) => {
  const newDatas = datas && !Array.isArray(datas) ? [datas] : datas;

  return (
    <Box className={className} {...props}>
      <FormSection name={name}>
        {newDatas &&
          newDatas.length > 0 &&
          (newDatas as DataType[]).map((data, index) => (
            <Data
              key={hash({
                ...data,
                customBottom: null,
                customTop: null,
                datas: null,
                innerElement: null,
                params: null,
              })}
              {...data}
              formName={formName}
              formValues={formValues}
              mode={mode}
              params={{
                ...params,
                sectionName: params?.sectionName
                  ? `${params.sectionName}.${name}`
                  : `${name}`,
              }}
              position={`${position ? `${position}.` : ''}${name}[${index}]`}
              wrapper={wrapper}
              wrapperFunc={wrapperFunc}
            />
          ))}
      </FormSection>
    </Box>
  );
};

const TitleSC = styled.div``;
const TabsSC = styled.div`
  div.grid + & {
    margin-top: ${(props: any) =>
      props && props.theme && props.theme.spacing && props.theme.defaultSpace
        ? props.theme.spacing[props.theme.defaultSpace]
        : '0'};
  }
`;

export const DataTabs: VFC<DataTabsProps> = ({
  componentType,
  barClassName,
  barItemClassName,
  className,
  formName,
  datas,
  mode,
  params,
  position,
  tabs,
  type,
  wrapper,
  wrapperFunc,
  ...props
}) => {
  const [searchParams, setSearchParams] = useState<{ [key: string]: any }>({});
  const { sc } = useContext(FormidableContext);

  const [tab, setTab] = useState<number>(0);
  const [infos, setInfos] = useState<TabsPageInfoProps[]>([]);
  const newDatas: DataProps[] | undefined = useMemo(
    () => (datas && !Array.isArray(datas) ? [datas] : datas),
    [datas],
  );

  const formValues = useSelector((state: any) =>
    state.form && formName && state.form[formName]
      ? state.form[formName].values
      : {},
  );

  useEffect(() => {
    let newTab = 0;

    if (
      typeof window !== 'undefined' &&
      window.location &&
      window.location.search
    ) {
      const search: { [key: string]: any } = {};
      window.location.search
        .slice(1)
        .split('&')
        .forEach(option => {
          const [key, value] = option.split('=');
          search[key] = value;
        });
      if (search.tab) {
        newTab = parseInt(search.tab, 10);
      }

      setSearchParams(search);
    }

    setTab(newTab);
  }, []);

  useEffect(() => {
    if (newDatas) {
      const newInfos: TabsPageInfoProps[] = [];
      newDatas.forEach((newData, i) => {
        let addNewTab = true;
        if ('string' !== typeof tabs[i]) {
          const tmpTab = tabs[i] as TabType;
          // On vérifie si il y a une condition
          if (tmpTab?.condition) {
            const newTest = params
              ? replaceTestParams(tmpTab.condition, params)
              : tmpTab.condition;

            addNewTab = verifyCondition({ formValues, test: newTest });
          }
        }

        if (addNewTab) {
          newInfos.push({
            index: i,
            isActive: tab === i,
            title:
              'string' === typeof tabs[i]
                ? (tabs[i] as string)
                : (tabs[i] as TabType)?.name,
          });
        }
      });
      setInfos(newInfos);
    }
  }, [formValues, newDatas, params, tab, tabs]);

  const handleButtonOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ): void => {
    const newTab = event.currentTarget.getAttribute('data-tab');

    if (newTab) {
      setTab(parseInt(newTab, 10));

      let location = window && window.location ? window.location.pathname : '/';
      const search: { [key: string]: any } = {
        ...searchParams,
        tab: newTab,
      };
      location += `?${Object.keys(search)
        .map(key => `${key}=${search[key]}`)
        .join('&')}`;

      window.history.replaceState(
        { location, tab: newTab },
        `tab ${newTab}`,
        location,
      );
    }
  };

  if ('collapse' === type) {
    return (
      <div>
        {infos.map(info => (
          <React.Fragment key={info.index}>
            <TitleSC as={sc && sc.groupTitle}>{info.title}</TitleSC>
            {newDatas && newDatas.length > info.index && (
              <Data
                {...(newDatas[info.index] as DataProps)}
                formName={formName}
                mode={mode}
                params={params}
                position={`${position ? `${position}.` : ''}datas[${
                  info.index
                }]`}
                wrapper={wrapper}
                wrapperFunc={wrapperFunc}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    );
  }

  return (
    <TabsSC as={sc && sc.tabs} {...props} className={className}>
      <TabsBar
        className={barClassName}
        handleButtonOnClick={handleButtonOnClick}
        infos={infos}
        itemClassName={barItemClassName}
      />
      {newDatas && newDatas.length > tab && (
        <Data
          {...(newDatas[tab] as DataProps)}
          formName={formName}
          mode={mode}
          params={params}
          position={`${position ? `${position}.` : ''}datas[${tab}]`}
          wrapper={wrapper}
          wrapperFunc={wrapperFunc}
        />
      )}
    </TabsSC>
  );
};

const DataConditionRender: FC<DataConditionProps> = ({
  datas,
  type = 'box',
  valid,
  ...props
}) => {
  if (!valid) {
    return null;
  }

  return <DataWithChildren {...props} componentType={type} datas={datas} />;
};

export const DataCondition = connect(
  (
    state: DefaultRootState,
    props: DataConditionProps,
  ): {
    valid: boolean;
  } => {
    if (!props.formName) {
      throw new Error('the formName props est obligatoire');
    }

    const formValues: { [key: string]: any } = getFormValues(props.formName)(
      state,
    );

    const { params, test } = props;

    const newTest = params ? replaceTestParams(test, params) : test;

    const valid = verifyCondition({ formValues, test: newTest });

    return {
      valid,
    };
  },
)(DataConditionRender);
