import { ModelType } from '@innedit/innedit-type';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Generic<T extends ModelType> extends ModelEspace<T> {
  constructor(props: ModelEspaceProps<T>) {
    super({
      labelFields: ['label'],
      ...props,
    });
  }
}

export default Generic;
