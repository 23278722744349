import type {
  DeliveryInfoType,
  DeliveryRuleType,
  DeliveryType,
  DocumentType,
  OrderProductType,
  OrderType,
  ProduitType,
} from '@innedit/innedit-type';
import { getDeliveryInfo } from '@innedit/innedit-type';
import { httpsCallable } from 'firebase/functions';

import { functions } from '../../../config/firebase';
import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';
import ProduitData from '../Produit';

class Delivery extends ModelEspace<DeliveryType> {
  constructor(props: Omit<ModelEspaceProps<DeliveryType>, 'collectionName'>) {
    super({
      ...props,
      collectionName: 'livraisons',
      orderDirection: props.orderDirection || 'desc',
      orderField: props.orderField || 'label',
    });
  }

  static async calculate(
    data: DocumentType<OrderType>,
  ): Promise<{ cost: number; error?: string; geocoding?: any }> {
    const { products } = data;
    if (!products || 0 === products.length) {
      return { cost: 0, error: "Il n'y a pas de produits  à livrer" };
    }

    let express = 0;

    const cumul: DeliveryInfoType = {
      dimensionMax: 0,
      express: false,
      isHeavy: false,
      isOutsize: false,
      nbVolume: 0,
      nbWeight: 0,
      sharing: 0,
      support: 0,
      totalVolume: 0,
      totalVW: 0,
      totalWeight: 0,
      volume: 0,
      volumetricWeight: 0,
      weight: 0,
    };

    const produitData = new ProduitData({ espaceId: data.espaceId });
    const produitDocs: Promise<{
      price: number;
      produit: ProduitType;
      quantity: number;
    }>[] = [];
    products.forEach(p => {
      if (p.id) {
        produitDocs.push(
          produitData.findById(p.id).then(documentSnapshot => ({
            price: p.price,
            produit: documentSnapshot,
            quantity: p.quantity,
          })),
        );
      }
    });

    const values = await Promise.all(produitDocs);
    values.forEach(({ produit, quantity }) => {
      const result = getDeliveryInfo(quantity, produit);

      express += result.express ? 1 : 0;
      cumul.dimensionMax += result.dimensionMax;
      cumul.isHeavy = cumul.isHeavy || result.isHeavy;
      cumul.nbVolume += result.nbVolume;
      cumul.nbWeight += result.nbWeight;
      cumul.sharing += result.sharing;
      cumul.support += result.support;
      cumul.totalVolume += result.totalVolume;
      cumul.totalVW += result.totalVW;
      cumul.totalWeight += result.totalWeight;
      cumul.volume += result.volume;
      cumul.volumetricWeight += result.volumetricWeight;
      cumul.weight += result.weight;
    });

    if (express > 0) {
      cumul.express = true;
    }

    if (cumul.totalWeight > 0 || cumul.totalVolume > 0) {
      const {
        contactAddress,
        contactCity,
        contactCountry,
        contactZip,
        espaceId,
      } = data;
      const transport = httpsCallable(functions, 'transport');
      const result: any = await transport({
        espaceId,
        action: 'estimation',
        country: contactCountry,
        extra: false,
        q: `${contactAddress} ${contactZip} ${contactCity} ${contactCountry}`,
        shippingInfo: cumul,
      });

      if (result?.data.errors) {
        const [message] = result.data.errors.map(
          (e: { message: string }) => e.message,
        );

        throw new Error(message);
      }

      return result.data;
    }

    throw new Error('Impossible de calculer le coût de la livraison');
  }

  static async estimate(
    espaceId: string,
    produits: OrderProductType[],
    country: string,
    q: string,
  ): Promise<{ cost: number; error?: string; geocoding?: any }> {
    if (!produits || 0 === produits.length) {
      return { cost: 0, error: "Il n'y a pas de produits  à livrer" };
    }

    let express = 0;

    const cumul: DeliveryInfoType = {
      dimensionMax: 0,
      express: false,
      isHeavy: false,
      isOutsize: false,
      nbVolume: 0,
      nbWeight: 0,
      sharing: 0,
      support: 0,
      totalVolume: 0,
      totalVW: 0,
      totalWeight: 0,
      volume: 0,
      volumetricWeight: 0,
      weight: 0,
    };

    const produitData = new ProduitData({ espaceId });
    const produitDocs: Promise<{
      price: number;
      produit: ProduitType;
      quantity: number;
    }>[] = [];
    produits.forEach(p => {
      if (p.id) {
        produitDocs.push(
          produitData.findById(p.id).then(documentSnapshot => ({
            price: p.price,
            produit: documentSnapshot,
            quantity: p.quantity,
          })),
        );
      }
    });

    const values = await Promise.all(produitDocs);

    values.forEach(({ produit, quantity }) => {
      const result = getDeliveryInfo(quantity, produit);

      express += result.express ? 1 : 0;
      cumul.dimensionMax += result.dimensionMax;
      cumul.isHeavy = cumul.isHeavy || result.isHeavy;
      cumul.nbVolume += result.nbVolume;
      cumul.nbWeight += result.nbWeight;
      cumul.sharing += result.sharing;
      cumul.support += result.support;
      cumul.totalVolume += result.totalVolume;
      cumul.totalVW += result.totalVW;
      cumul.totalWeight += result.totalWeight;
      cumul.volume += result.volume;
      cumul.volumetricWeight += result.volumetricWeight;
      cumul.weight += result.weight;
    });

    if (express > 0) {
      cumul.express = true;
    }

    if (cumul.totalWeight > 0 || cumul.totalVolume > 0) {
      const transport = httpsCallable(functions, 'transport');
      const result: any = await transport({
        country,
        espaceId,
        q,
        action: 'estimation',
        extra: false,
        shippingInfo: cumul,
      });

      if (result?.data.errors) {
        const [message] = result.data.errors.map(
          (e: { message: string }) => e.message,
        );

        throw new Error(message);
      }

      return result.data;
    }

    throw new Error('Impossible de calculer le coût de la livraison');
  }

  static calculateWithShippingValue(
    value: Partial<DeliveryRuleType>,
    info: DeliveryInfoType,
  ): number {
    const { nbWeight, nbVolume, support, sharing, totalVolume, totalWeight } =
      info;
    const { express, pec, coefVolume, coefWeight } = value;
    let price: number;

    const volume = totalVolume || 0;
    const weight = totalWeight || 0;

    if (express) {
      price = Math.round(
        (nbWeight || nbVolume) * Number(pec || 0) +
          (support || 0) -
          (sharing || 0),
      );
    } else {
      price = Math.round(
        volume * Number(coefVolume || 0) +
          weight * Number(coefWeight || 0) +
          Number(pec || 0) +
          (support || 0) -
          (sharing || 0),
      );
    }

    // Arrondir le prix à 5 euros près
    return Math.ceil(price / 5) * 5;
  }
}

export default Delivery;
