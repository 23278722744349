import styled from '@emotion/styled';
import { ChannelType, DocumentType } from '@innedit/innedit-type';
import { DataWithChildren } from 'packages/formidable';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { ChannelsListProps } from '~/datas/props';

const ChannelStyled = styled.div`
  div > div {
    margin-bottom: 0px !important;
  }
`;

const ChannelsList: FC<ChannelsListProps> = ({
  display,
  name,
  title = "Canaux d'interactions",
}) => {
  const [channels] = useSelector(
    (state: { cms: { channels: DocumentType<ChannelType>[] } }) => [
      state.cms.channels,
    ],
  );

  if (!channels || 0 === channels.length) {
    return null;
  }

  const newChannels = channels.filter(c => c.kind !== 'redirect');

  if (0 === newChannels.length) {
    return null;
  }

  return (
    <ChannelStyled>
      <DataWithChildren
        componentType="group"
        datas={newChannels.map(channel => {
          let description: string;

          switch (channel.kind) {
            case 'profile': {
              description = channel.username ?? channel.label;
              break;
            }

            case 'website': {
              if (channel.url) {
                description = channel.url.toLowerCase();
              } else {
                description = channel.label;
              }

              break;
            }
            default:
              description = channel.label;
          }

          return {
            description,
            componentType: 'input',
            labelShow: false,
            name: `${name}.${channel.id}.isVisible`,
            type: 'checkbox',
          };
        })}
        display={display}
        title={title}
      />
    </ChannelStyled>
  );
};

export default ChannelsList;
