import { ArticleType, DataType, DocumentType } from '@innedit/innedit-type';
import objectHash from 'object-hash';
import { Box, Data, DataProps } from 'packages/formidable';
import { GroupBody, GroupTitle } from 'packages/formidable/components/Group';
import { ArticleData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import Button from '~/components/Button';
import Media from '~/components/Media';

export interface DataArticleParentProps
  extends Omit<DataProps, 'componentType'> {
  articleId: string;
  datas?: DataType[];
  espaceId: string;
}

const DataArticleParent: FC<DataArticleParentProps> = ({
  articleId,
  datas,
  espaceId,
  title,
  titleProps,
  ...props
}) => {
  const [article, setArticle] = useState<DocumentType<ArticleType>>();

  useEffect(() => {
    let isMounted = true;
    if (articleId) {
      const articleData = new ArticleData({
        espaceId,
      });

      articleData.watchById(articleId, newDocument => {
        if (isMounted) {
          setArticle(newDocument);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [articleId, espaceId]);

  return (
    <Box>
      {article && (
        <>
          {title && <GroupTitle {...titleProps}>{title}</GroupTitle>}
          <GroupBody className="bg-light-500">
            <div className="grid grid-cols-3 gap-3">
              {article.medias && article.medias.length > 0 && (
                <div>
                  <Media
                    className="rounded"
                    media={article.medias[0]}
                    options="w=65&h=65"
                  />
                </div>
              )}

              <div className="col-span-2">
                <h2 className="text-md">{article.label}</h2>
                <Button
                  to={`/espaces/${espaceId}/articles/${articleId}/update/`}
                  variant="link"
                >
                  {`Retour à l'article`}
                </Button>
              </div>
            </div>
            {datas &&
              datas.length > 0 &&
              datas.map(data => (
                <Data
                  key={objectHash(data)}
                  {...props}
                  {...data}
                  params={{
                    ...props.params,
                    parentCollectionName: 'articles',
                    parentId: articleId,
                  }}
                />
              ))}
          </GroupBody>
        </>
      )}
    </Box>
  );
};

export default DataArticleParent;
