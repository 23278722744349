import { DocumentType, FeatureType } from '@innedit/innedit-type';
import { DataField, DataFieldProps } from 'packages/formidable';
import { FeatureData, WhereProps } from 'packages/innedit';
import React, { FC, memo, useEffect, useState } from 'react';

export interface DataFeaturesSelectProps
  extends Omit<DataFieldProps, 'componentType'> {
  espaceId: string;
  orderDirection?: 'asc' | 'desc';
  orderField?: keyof FeatureType;
  wheres?: WhereProps;
}
const DataFeaturesSelect: FC<DataFeaturesSelectProps> = ({
  espaceId,
  className,
  formName,
  label,
  labelShow,
  name,
  orderDirection,
  orderField,
  wheres,
}) => {
  const [options, setOptions] = useState<DocumentType<FeatureType>[]>();

  useEffect(() => {
    const feature = new FeatureData({
      espaceId,
      orderDirection,
      orderField,
    });

    const unsub: () => void = feature.watch(
      newDocs => {
        setOptions(newDocs);
      },
      {
        wheres,
      },
    );

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, orderDirection, orderField, wheres]);

  if (!options || 0 === options.length) {
    return null;
  }

  return (
    <DataField
      className={className}
      componentType="select"
      formName={formName}
      label={label}
      labelShow={labelShow}
      name={name}
      options={options.map(item => ({
        label: item.label,
        value: item.id,
      }))}
    />
  );
};

const propsAreEqual = (
  prevProps: DataFeaturesSelectProps,
  nextProps: DataFeaturesSelectProps,
): boolean =>
  prevProps.espaceId === nextProps.espaceId &&
  prevProps.collectionName === nextProps.collectionName &&
  prevProps.orderDirection === nextProps.orderDirection &&
  prevProps.orderField === nextProps.orderField;

export default memo(DataFeaturesSelect, propsAreEqual);
