import styled from '@emotion/styled';
import { DataType, initializeValues } from '@innedit/innedit-type';
import hash from 'object-hash';
import {
  DataFieldArrayProps,
  DataFieldProps,
  DataWithChildrenProps,
} from 'packages/formidable/components/Data/props';
import React, { FC, SyntheticEvent, useContext } from 'react';
import { FormSection, WrappedFieldArrayProps } from 'redux-form';

import HOCGroup from '~/components/Group/HOC';

import FormidableContext from '../../../FormidableContext';
import Field from '../Field';
import Data from '../index';

const ButtonSC = styled.button<{
  iconLeft?: any;
  size?: string;
  status?: string;
  variant?: string;
}>``;

const DataArrayRender: FC<
  WrappedFieldArrayProps & Omit<DataFieldArrayProps, 'name'>
> = ({
  addButton,
  bodyProps,
  className,
  customInfos,
  customInfosProps,
  datas,
  fieldProps,
  fields,
  formName,
  formValues,
  groupProps,
  params,
  removeButton,
}) => {
  const { sc } = useContext(FormidableContext);
  const newDatas = datas && !Array.isArray(datas) ? [datas] : datas;

  const handleAddButtonOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();

    const initialDatas = initializeValues(newDatas);
    fields.push(Object.keys(initialDatas).length > 0 ? initialDatas : '');
  };

  const handleRemoveButtonOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();
    const index = event.currentTarget.getAttribute('data-index');

    if (index) {
      fields.remove(parseInt(index, 10));
    }
  };

  const WrapperGroup = groupProps ? HOCGroup : React.Fragment;
  const wrapperProps = groupProps
    ? {
        className,
        ...groupProps,
        addOnClick: handleAddButtonOnClick,
      }
    : undefined;

  return (
    <WrapperGroup {...wrapperProps}>
      {!groupProps && addButton && (
        <ButtonSC
          as={sc && sc.button}
          className="absolute top-0 right-0"
          iconLeft="add"
          onClick={handleAddButtonOnClick}
          variant="link"
        />
      )}
      {fields && (
        <div>
          {fields.map((field, index) => {
            const removeCmp = removeButton && (
              <ButtonSC
                as={sc && sc.button}
                className="ml-1 h-[42px]"
                color="neutral"
                data-index={index}
                iconLeft="remove"
                onClick={handleRemoveButtonOnClick}
                size="xs"
                status="delete-action"
                variant="outline"
              />
            );

            if (newDatas && newDatas.length > 0) {
              if (
                newDatas.length > 1 ||
                (newDatas[0] as DataWithChildrenProps).datas ||
                (newDatas[0] as DataFieldProps).name
              ) {
                return (
                  <FormSection
                    key={`${field}_${hash({
                      ...newDatas,
                      datas: null,
                      params: null,
                    })}`}
                    className="flex flex-1"
                    data-index={index}
                    name={field}
                  >
                    {(newDatas as DataType[]).map(value => (
                      <Data
                        key={`${field}_${hash(value)}`}
                        className="flex-1"
                        {...value}
                        customInfosProps={customInfosProps}
                        formName={formName}
                        formValues={formValues}
                        params={{
                          ...params,
                          index,
                          count: index + 1,
                          name: field,
                          sectionName: field,
                        }}
                      />
                    ))}
                    {customInfos}
                    {removeCmp}
                  </FormSection>
                );
              }

              return (
                <div key={field} className="flex flex-1" data-index={index}>
                  <Data
                    className="flex-1"
                    {...newDatas[0]}
                    formName={formName}
                    formValues={formValues}
                    name={field}
                    params={{
                      ...params,
                      name: field,
                    }}
                  />
                  {customInfos}
                  {removeCmp}
                </div>
              );
            }

            return (
              <div key={field} className="flex flex-1" data-index={index}>
                <Field
                  componentType="input"
                  formName={formName}
                  formValues={formValues}
                  labelShow={false}
                  name={field}
                  params={{
                    ...params,
                    name: field,
                  }}
                  wrapperProps={{
                    className: 'flex-1',
                  }}
                  {...fieldProps}
                />
                {customInfos}
                {removeCmp}
              </div>
            );
          })}
        </div>
      )}
    </WrapperGroup>
  );
};

export default DataArrayRender;
