import { DocumentType, TemplateType } from '@innedit/innedit-type';
import { TemplateData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { DataTemplatesSelectProps } from '~/datas/props';

import { DataField } from '../../packages/formidable';

const DataTemplatesSelect: FC<DataTemplatesSelectProps> = ({
  espaceId,
  name,
  ...props
}) => {
  const [docs, setDocs] = useState<DocumentType<TemplateType>[]>();

  useEffect(() => {
    let isMounted = true;

    const templateData = new TemplateData({ espaceId });
    const unsub = templateData.watch(newDocs => {
      if (isMounted) {
        setDocs(newDocs);
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId]);

  return (
    <DataField
      {...props}
      componentType="select"
      name={name}
      options={docs?.map(doc => ({
        label: doc.label,
        value: doc.id,
      }))}
    />
  );
};

export default DataTemplatesSelect;
