import { DocumentType, ProfessionalType } from '@innedit/innedit-type';
import { ProfessionalData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { DataField, DataFieldProps } from '../../../packages/formidable';

export interface DataProfessionalsSelectProps
  extends Omit<DataFieldProps, 'componentType'> {
  espaceId: string;
  docId: string;
  formName: string;
}

const DataProfessionalsSelect: FC<DataProfessionalsSelectProps> = ({
  espaceId,
  docId,
  formName,
  label,
  name,
}) => {
  const [professionals, setProfessionals] =
    useState<DocumentType<ProfessionalType>[]>();

  useEffect(() => {
    let isMounted = true;

    const professionalData = new ProfessionalData({
      espaceId,
      orderDirection: 'asc',
      orderField: 'label',
    });

    const wheres: { [key: string]: any } = {
      hidden: false,
      stripeAccountHidden: false,
      stripeAccountVerified: true,
    };

    professionalData
      .find({
        wheres,
      })
      .then(documents => {
        if (isMounted) {
          setProfessionals(documents);
        }

        return true;
      })
      .catch(toast.error);

    return () => {
      isMounted = false;
    };
  }, [espaceId]);

  if (!docId) {
    return null;
  }

  if (!professionals) {
    return <p>Chargement en cours</p>;
  }

  if (0 === professionals.length) {
    return <p>Aucun professionnel</p>;
  }

  return (
    <DataField
      componentType="select"
      formName={formName}
      label={label}
      name={name}
      options={professionals.map(doc => ({
        label: `${doc.label}`,
        value: doc.id,
      }))}
    />
  );
};

export default DataProfessionalsSelect;
