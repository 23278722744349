import { DocumentType, GroupType } from '@innedit/innedit-type';
import { DataField, DataFieldProps } from 'packages/formidable';
import { GroupData, WhereProps } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

export interface DataGroupsSelectProps
  extends Omit<DataFieldProps, 'componentType'> {
  espaceId: string;
  orderDirection?: 'asc' | 'desc';
  orderField?: string;
  wheres?: WhereProps;
}
const DataGroupsSelect: FC<DataGroupsSelectProps> = ({
  espaceId,
  className,
  formName,
  label,
  labelShow,
  name,
  orderDirection,
  orderField,
  wheres,
}) => {
  const [options, setOptions] = useState<DocumentType<GroupType>[]>();

  useEffect(() => {
    const group = new GroupData({
      espaceId,
    });

    const unsub: () => void = group.watch(
      newDocs => {
        setOptions(newDocs);
      },
      {
        wheres,
      },
    );

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, orderDirection, orderField, wheres]);

  if (!options || 0 === options.length) {
    return null;
  }

  return (
    <DataField
      className={className}
      componentType="select"
      formName={formName}
      label={label}
      labelShow={labelShow}
      name={name}
      options={options.map(item => ({
        label: item.label,
        value: item.id,
      }))}
    />
  );
};

export default DataGroupsSelect;
