import { DocumentType, ProduitType } from '@innedit/innedit-type';
import { ProduitData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Box, DataFieldProps } from '../../packages/formidable';

export interface DataProductProps
  extends Omit<DataFieldProps, 'componentType'> {
  espaceId: string;
}
const DataProduct: FC<DataProductProps> = function ({
  espaceId,
  formName,
  name,
  params,
}) {
  const [product, setProduct] = useState<DocumentType<ProduitType>>();
  const dataForm = params[formName];

  useEffect(() => {
    let isMounted = true;
    if (dataForm && dataForm[name]) {
      const productId = dataForm[name];
      const model = new ProduitData({ espaceId });

      model
        .findById(productId)
        .then(snapshot => {
          if (isMounted) {
            setProduct(snapshot);
          }

          return isMounted;
        })
        .catch(toast.error);
    }

    return () => {
      isMounted = false;
    };
  }, [dataForm, name]);

  if (!product) {
    return null;
  }

  return (
    <Box className="flex flex-row space-x-3 items-center">
      {product.medias && product.medias.length > 0 && (
        <div>
          <img
            alt={product.medias[0].name}
            src={`${product.medias[0].origin}/h_50,w_50,c_contain,b_ffffff/${product.medias[0].pathname}`}
          />
        </div>
      )}
      <strong>{product.label}</strong>
    </Box>
  );
};

export default DataProduct;
