import { DocumentType, PriceType, ProduitType } from '@innedit/innedit-type';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { PriceData, ProduitData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Media from '~/components/Media';
import Price from '~/components/Price';
import svg from '~/images/next-photo.svg';

const Item: FC<{
  data: {
    createdAt: string;
    isAnswer: boolean;
    productId?: string;
    text?: string;
  };
  espaceId: string;
  index: number;
  nbMessages: number;
}> = ({ data, espaceId, index, nbMessages }) => {
  const { t } = useTranslation();
  const [product, setProduct] = useState<DocumentType<ProduitType>>();
  const [prices, setPrices] = useState<DocumentType<PriceType>[]>();

  useEffect(() => {
    let isMounted = true;
    const model = new ProduitData({
      espaceId,
    });
    if (data.productId) {
      model
        .findById(data.productId)
        .then(document => {
          if (isMounted) {
            setProduct(document);
            const priceModel = new PriceData({
              espaceId,
              parentCollectionName: 'produits',
              parentId: data.productId,
            });
            priceModel
              .find()
              .then(docs => {
                if (isMounted) {
                  setPrices(docs);
                }

                return isMounted;
              })
              .catch(error => toast.error(error.message));
          }

          return isMounted;
        })
        .catch(error => toast.error(error.message));
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const volume =
    ((product?.width ?? 0) * (product?.height ?? 0) * (product?.depth ?? 0)) /
    100 /
    100 /
    100;

  return (
    <div
      className={classnames('flex flex-col -mx-6', {
        'bg-light-500': 1 === index % 2,
        'border-t': index !== 0,
        'rounded-b-[8px]': nbMessages - 1 === index,
      })}
    >
      <div className="px-6 py-1.5 flex flex-col space-y-3">
        {product && (
          <div className="flex space-x-3 flex-1">
            <div>
              {product.medias && product.medias.length > 0 ? (
                <Media media={product.medias[0]} options="h=80&w=80" />
              ) : (
                <img alt="Upload files" height={80} src={svg} width={80} />
              )}
            </div>
            <div className="flex flex-col w-full">
              <h2 className="text-[15px] font-normal">{product.label}</h2>
              <dl className="grid grid-cols-2 gap-x-3">
                {product.isPhysical && (
                  <>
                    <dt>Dimensions</dt>
                    <dd className="text-right">
                      <span>{product.width}</span>
                      <span>{product.depth}</span>
                      <span>{product.height}</span>
                      <span>
                        {volume} m<sup>3</sup>
                      </span>
                    </dd>
                  </>
                )}
                <dt>Age</dt>
                <dd className="text-right text-[13px]">
                  {t('inquiry.products.age', {
                    count: dayjs().diff(dayjs(product.createdAt), 'day'),
                  })}
                </dd>
              </dl>
              {prices &&
                prices.length > 0 &&
                prices.map(price => <Price key={price.id} data={price} />)}
            </div>
          </div>
        )}
        {data.text && <div>{data.text}</div>}
      </div>
    </div>
  );
};

export default Item;
