import { css } from '@emotion/react';
import styled from '@emotion/styled';
import classnames from 'classnames';
import objectHash from 'object-hash';
// eslint-disable-next-line import/no-cycle
import Data from 'packages/formidable/components/Data';
import { GroupProps } from 'packages/formidable/components/Data/props';
import { createStyles } from 'packages/formidable/core/functions';
import convertParams from 'packages/formidable/utils/convertParams';
import React, { FC, PropsWithChildren, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonProps } from '~/components/Button';
import Modal from '~/datas/Attributes/Modal';
import IconFileCode from '~/icons/FileCode';
import colors from '~/styles/colors';
import font from '~/styles/font';
import spacing from '~/styles/spacing';
import { screens } from '~/styles/theme';

export const GroupDescription = styled.p`
  margin-bottom: ${spacing[5]};

  color: ${colors.neutral[300]};
  font-style: italic;
  font-size: ${font.size.sm[0]};
`;

interface GroupTitleProps {
  'data-display'?: string;
}

export const Group = styled.div<PropsWithChildren<any>>`
  ${createStyles}
  position: relative;
  max-width: 100vw;
  box-sizing: border-box;
  margin-bottom: 1.5rem;

  display: flex;
  flex-direction: column;

  @media (min-width: ${screens.md}) {
    width: 100%;
  }

  ${props => {
    if ('inside' === props['data-display']) {
      return css`
        border-top: 1px solid ${colors.light[600]};
        margin: 0 -${spacing[6]};
        padding: ${spacing[6]} ${spacing[6]} 0 ${spacing[6]};
        // max-width: calc(100vw - ${spacing[12]});

        @media (min-width: ${screens.md}) {
          width: auto;
        }
      `;
    }

    if ('content' === props['data-display']) {
      return css`
        display: grid;
        gap: 1.5rem;
        @media (min-width: ${screens.xl}) {
          grid-template-columns: repeat(3, minmax(0, 1fr));

          > div:last-child {
            grid-column: span 2 / span 2;
          }
        }

        > div:first-of-type h2,
        > h2 {
          font-weight: 600;
          font-size: ${font.size.base[0]};
          line-height: ${(font.size.base[1] as { lineHeight: string })
            .lineHeight};
        }

        > div:first-of-type h2 {
          margin-top: 0;
        }

        & + & {
          margin-top: ${spacing[6]};
        }
      `;
    }

    return null;
  }};
`;

export const GroupBody = styled.div`
  background: ${colors.light[50]};
  padding: ${spacing[6]};

  // box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

  @media (min-width: ${screens.md}) {
    border-radius: 0.5rem;
    border-width: 1px;
  }

  > .group:last-child {
    margin-bottom: 0;
  }
`;

export const GroupHeader = styled.div`
  position: relative;
  margin-left: ${spacing[3]};
  margin-right: ${spacing[3]};
  @media (min-width: ${screens.md}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const GroupTitle = styled.h2<GroupTitleProps>`
  text-align: left;
  margin-bottom: ${spacing[1]};
  font-size: ${font.size.lg[0]};
  margin-left: ${spacing[6]};

  @media (min-width: ${screens.md}) {
    margin-left: 0;
  }

  ${props => {
    if (props['data-display'] && 'inside' === props['data-display']) {
      return css`
        font-weight: 300 !important;
        font-size: ${font.size.sm[0]} !important;
      `;
    }

    if (props['data-display'] && 'ghost' === props['data-display']) {
      return css`
        font-weight: inherit !important;
        font-size: ${font.size.sm[0]} !important;
      `;
    }

    return null;
  }}
`;

const GroupComponent: FC<PropsWithChildren<GroupProps>> = ({
  actions,
  attributesName,
  bodyRemoveTag = false,
  bodyProps,
  className,
  customInfos,
  children,
  description,
  descriptionProps,
  display,
  formName,
  headerProps,
  params,
  title,
  titleProps,
  titleParams,
  ...props
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleOpenOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpenModal(true);
  };

  const handleCloseOnClick = (event?: SyntheticEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    setOpenModal(false);
  };

  const GroupBodyCmp =
    (display && ['inside', 'ghost'].includes(display)) || bodyRemoveTag
      ? React.Fragment
      : GroupBody;
  const groupBodyProps =
    (display && ['inside', 'ghost'].includes(display)) || bodyRemoveTag
      ? {}
      : {
          ...bodyProps,
          className: classnames('group-body', bodyProps?.className),
        };

  const TitleCmp = title ? (
    <GroupTitle data-display={display} {...titleProps}>
      {t ? t(title, convertParams(titleParams, params)) : title}
    </GroupTitle>
  ) : undefined;

  const DescriptionCmp = description ? (
    <GroupDescription {...descriptionProps}>
      {t ? t(description) : description}
    </GroupDescription>
  ) : undefined;

  const CustomInfosCmp =
    actions || attributesName || customInfos ? (
      <div className="flex space-x-3 absolute min-h-0 right-0 top-0">
        {actions &&
          actions.length > 0 &&
          actions.map((action: ButtonProps) => (
            <Button
              key={objectHash({ action })}
              {...action}
              variant="sub-action"
            />
          ))}

        {attributesName && (
          <Button
            iconLeft={IconFileCode}
            onClick={handleOpenOnClick}
            title="Éditer le style"
            variant="sub-action"
          />
        )}

        {customInfos}
      </div>
    ) : undefined;

  return (
    <Group className={classnames('group', className)} data-display={display}>
      {(undefined !== CustomInfosCmp || (title && description)) && (
        <GroupHeader className="relative" {...headerProps}>
          {CustomInfosCmp}
          {TitleCmp}
          {DescriptionCmp}
        </GroupHeader>
      )}
      {undefined === CustomInfosCmp && title && !description && TitleCmp}
      {undefined === CustomInfosCmp && !title && description && DescriptionCmp}
      {attributesName && openModal && (
        <Modal
          closeOnClick={handleCloseOnClick}
          formName={formName}
          name={attributesName}
          params={params}
          title={attributesName}
        />
      )}
      <GroupBodyCmp {...groupBodyProps}>
        {children}

        {attributesName && (
          <div
            className={classnames('mt-3', {
              'border-t pt-6 -mx-6 px-6':
                !display || !['inside'].includes(display),
            })}
          >
            <Data
              componentType="attributes"
              displayMaxWidth={props?.displayMaxWidth}
              formName={formName}
              name={attributesName}
              params={params}
              type="show"
            />
          </div>
        )}
      </GroupBodyCmp>
    </Group>
  );
};

export default GroupComponent;
