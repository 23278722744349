import { CommentType, ModelType } from '@innedit/innedit-type';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

export interface ModelEspaceContentProps<T extends ModelType>
  extends ModelEspaceProps<T> {
  parentCollectionName: string;
  parentId: string;
}

class Comment extends ModelEspace<CommentType> {
  constructor(
    props: Omit<ModelEspaceContentProps<CommentType>, 'collectionName'>,
  ) {
    super({
      ...props,
      collectionName: 'comments',
      orderDirection: props.orderDirection || 'asc',
      orderField: props.orderField || 'createdAt',
    });
  }

  public initialize(data?: Partial<CommentType>): Partial<CommentType> {
    return super.initialize({
      medias: [],
      ...data,
    });
  }
}

export default Comment;
