import { OrderProductType } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent } from 'react';
import { FieldArrayFieldsProps } from 'redux-form';

import ItemEditable from './Editable';
import ItemNoEditable from './NoEditable';

const Item: FC<{
  deleteOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  editable: boolean;
  espaceId: string;
  index: number;
  name: string;
  produits: FieldArrayFieldsProps<OrderProductType>;
}> = ({ deleteOnClick, editable, espaceId, index, name, produits }) => {
  const item = produits.get(index);

  if (editable) {
    return (
      <ItemEditable deleteOnClick={deleteOnClick} index={index} name={name} />
    );
  }

  return <ItemNoEditable espaceId={espaceId} item={item} />;
};

export default Item;
