import {
  DataFieldType,
  DataType,
  DocumentType,
  EspaceType,
  FirebaseConfigType,
  initializeFields,
  initializeValues,
  ModelType,
  removeUndefined,
  remplaceFieldsIntoDatas,
  requiredData,
} from '@innedit/innedit-type';
import dayjs from 'dayjs';
import FirebaseFirestore, {
  addDoc,
  collection,
  doc,
  documentId,
  Firestore,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  query,
  setDoc,
  startAfter,
  startAt,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import { mergeWith } from 'lodash';
import { SearchResponse } from 'typesense/lib/Typesense/Documents';

import { auth, firestore } from '../../config/firebase';
import client from '../../config/typesense';
import {
  FindOptionsProp,
  getTypesenseFilters,
  InneditError,
  SearchOptionsProp,
  updateConstraints,
  WatchOptionsProp,
  WhereProps,
} from '../functions';

export interface ListTabType {
  allowSorting?: boolean;
  className?: string;
  label: string;
  itemMode?: 'grid' | 'list';
  orderDirection?: 'asc' | 'desc';
  orderField?: string;
  pathname: string;
  wheres?: WhereProps;
}

export interface ModelProps<T extends ModelType> {
  addButtonLabel?: string;
  canDoSearch?: boolean;
  collectionName: string;
  datas?: DataType | DataType[];
  espace?: DocumentType<EspaceType>;
  espaceId?: string;
  fields?: DataFieldType | DataFieldType[];
  labelFields?: (keyof T)[];
  orderDirection?: 'asc' | 'desc';
  orderField?: keyof T;
  params?: DataType | DataType[];
  parentCollectionName?: string;
  parentId?: string;
  perPage?: number;
  queryBy?: string;
  tabs?: ListTabType | ListTabType[];
  wheres?: WhereProps;
}

abstract class Model<T extends ModelType> {
  public addButtonLabel: string;
  public canDoSearch: boolean;
  public collectionName: string;
  public datas?: DataType[];

  public espace?: DocumentType<EspaceType>;
  public espaceId?: string;

  public fields?: DataFieldType[];
  public firebase?: FirebaseConfigType;

  public labelFields: (keyof DocumentType<T>)[];

  public params?: DataType[];
  public parentCollectionName?: string;
  public parentId?: string;
  public perPage?: number;
  public queryBy: string;
  public orderDirection: 'asc' | 'desc';
  public orderField: keyof T;

  public tabs?: ListTabType[];

  public wheres: WhereProps = {};

  protected constructor(props: ModelProps<T>) {
    const {
      addButtonLabel,
      canDoSearch,
      collectionName,
      datas,
      espace,
      espaceId,
      fields,
      labelFields,
      orderDirection,
      orderField,
      params,
      parentCollectionName,
      parentId,
      perPage,
      queryBy,
      tabs,
      wheres,
    } = props;

    if (!collectionName) {
      throw new Error('collectionName obligatoire');
    }

    this.addButtonLabel = addButtonLabel || 'Ajouter';
    this.collectionName = collectionName;
    this.canDoSearch = Boolean(canDoSearch);
    this.datas = datas && !Array.isArray(datas) ? [datas] : datas;
    this.espace = espace;
    this.espaceId = espaceId;
    this.fields = fields && !Array.isArray(fields) ? [fields] : fields;
    this.firebase = espace?.firebase;
    this.labelFields = labelFields ?? ['label'];
    this.orderDirection = orderDirection || 'desc';
    this.orderField = orderField ?? 'datetime';
    this.params = params && !Array.isArray(params) ? [params] : params;
    this.parentCollectionName = parentCollectionName;
    this.parentId = parentId;
    this.perPage = perPage ?? 40;
    this.queryBy = queryBy ?? 'label';
    this.tabs = tabs && !Array.isArray(tabs) ? [tabs] : tabs;
    this.labelFields = labelFields ?? ['label'];
    this.wheres = wheres || {};

    if (parentCollectionName && parentId) {
      // on ajout les conditions au wheres
      mergeWith(this.wheres, {
        parentCollectionName,
        parentId,
      });
    }
    if (espaceId) {
      mergeWith(this.wheres, {
        espaceId,
      });
    }
  }

  public async create(data: Partial<T>, id?: string): Promise<DocumentType<T>> {
    const newData = { ...data };
    const user = auth.currentUser;
    // TODO voir pour savoir s'il est nécessaire de remettre ce test
    // if (!user) {
    //   throw new Error(
    //     "L'utilisateur doit être connecté pour enregistrer un document",
    //   );
    // }

    const cleanData = this.clean(
      {
        createdByUser: user?.uid,
        ...newData,
      },
      true,
    ) as T;

    let docRef;
    if (id) {
      // l'id a été généré avant, il faut donc récupérer la ref
      docRef = doc(this.getCollectionRef(), id);
      await setDoc(docRef, cleanData);
    } else {
      docRef = await addDoc<T>(this.getCollectionRef(), cleanData);
    }

    return {
      ...cleanData,
      id: docRef.id,
    };
  }

  public archive(id: string): Promise<void> {
    const user = auth.currentUser;

    if (!user) {
      throw new Error(
        "L'utilisateur doit être connecté pour archiver un document",
      );
    }

    const date = dayjs().toISOString();
    const ref = doc(this.getCollectionRef(), id);

    return updateDoc<ModelType>(ref, {
      archived: true,
      archivedAt: date,
      archivedByUser: user.uid,
      updatedAt: date,
      updatedByUser: user.uid,
    });
  }

  public delete(id: string): Promise<void> {
    const user = auth.currentUser;

    if (!user) {
      throw new Error(
        "L'utilisateur doit être connecté pour supprimer un document",
      );
    }

    const date = dayjs().toISOString();
    const ref = doc(this.getCollectionRef(), id);

    return updateDoc<ModelType>(ref, {
      deleted: true,
      deletedAt: date,
      deletedByUser: user.uid,
      updatedAt: date,
      updatedByUser: user.uid,
    });
  }

  public async duplicate(
    id: string,
    data: T,
  ): Promise<FirebaseFirestore.DocumentReference<T>> {
    if (!data) {
      throw new Error('Le document a dupliqué ne possède aucune donnée');
    }

    const docRef = doc(
      this.getFirestore(),
      this.collectionName,
    ) as FirebaseFirestore.DocumentReference<T>;

    await setDoc(docRef, this.clean({ ...data, parent: id }, true) as T);

    return docRef;
  }

  protected getCollectionRef(): FirebaseFirestore.CollectionReference<T> {
    const path = this.collectionName;
    // const paths: string[] = [];

    // if (this.parentId && this.parentCollectionName) {
    //   [path, ...paths] = this.parentCollectionName.split('/');
    //   if (!paths) {
    //     paths = [];
    //   }
    //   paths.push(this.parentId);
    //   paths.push(this.collectionName);
    // }

    return collection(
      this.getFirestore(),
      path,
      // ...paths,
    ) as FirebaseFirestore.CollectionReference<T>;
  }

  // eslint-disable-next-line class-methods-use-this
  protected getFirestore(): Firestore {
    return firestore;
  }

  public getNewDocId(): string {
    return doc(this.getCollectionRef()).id;
  }

  public getDatas(): DataType[] | undefined {
    return this.fields
      ? remplaceFieldsIntoDatas(this.fields, this.datas)
      : this.params;
  }

  public initialize(data?: Partial<T>): Partial<T> {
    const initializeData = this.initializeData(data);

    return this.clean(initializeData) as Partial<T>;
  }

  private initializeData(data?: Partial<T>): Partial<T> {
    const datas = this.fields
      ? initializeFields(this.fields)
      : initializeValues<T>(this.getDatas());

    return {
      ...datas,
      ...data,
    } as Partial<T>;
  }

  public extractAttributes(
    data: Partial<T>,
    initialValues?: Partial<T>,
  ): Partial<T> {
    const attributes: Partial<T> = {};
    const initializedData =
      initialValues ||
      (this.fields && initializeFields(this.fields)) ||
      initializeValues(this.getDatas());
    Object.keys(initializedData).forEach(key => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      attributes[key] = data[key];
    });

    return attributes;
  }

  public extractRequiredAttributes(): { [key: string]: any } {
    return requiredData(this.getDatas());
  }

  public async find(options?: FindOptionsProp<T>): Promise<DocumentType<T>[]> {
    let constraints = [where('deleted', '==', false)];

    let orderField = (options?.orderField ?? this.orderField) as
      | string
      | undefined;
    const wheres = options?.wheres
      ? mergeWith(options.wheres, this.wheres)
      : this.wheres;

    if (orderField && wheres[orderField]) {
      orderField = undefined;
    }

    constraints = updateConstraints(constraints, {
      orderField,
      wheres,
      orderDirection: options?.orderDirection || this.orderDirection,
    });

    if (options?.startAfter) {
      // c'est l'id du document
      const after = await getDoc(
        doc(this.getFirestore(), this.collectionName, options.startAfter),
      );
      constraints.push(startAfter(after));
    }
    if (options?.startAt) {
      // c'est l'id du document
      const at = await getDoc(
        doc(this.getFirestore(), this.collectionName, options.startAt),
      );
      constraints.push(startAt(at));
    }

    constraints.push(
      limit(
        options?.limit ??
          (parseInt(String(process.env.GATSBY_INNEDIT_WATCH_LIMIT), 10) || 250),
      ),
    );

    const q = query(this.getCollectionRef(), ...constraints);

    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map(d => ({ id: d.id, ...(d.data() as T) }));
  }

  public async findById(id: string): Promise<DocumentType<T>> {
    const documentSnapshot = await getDoc<T>(
      doc(
        this.getCollectionRef(),
        id,
      ) as FirebaseFirestore.DocumentReference<T>,
    );

    if (
      !documentSnapshot ||
      !documentSnapshot.exists() ||
      documentSnapshot.get('deleted')
    ) {
      throw new Error("Le document n'existe pas ou a été supprimé");
    }

    return {
      id,
      ...(documentSnapshot.data() as T),
    };
  }

  public async findByIds(ids: string[]): Promise<DocumentType<T>[]> {
    const promises = ids.map(id => getDoc(doc(this.getCollectionRef(), id)));

    const docs = await Promise.all(promises);
    // const q = query<T>(
    //   this.getCollectionRef(),
    //   where(documentId(), 'in', ids.slice(0, 10)),
    //   // orderBy(this.orderField, this.orderDirection),
    // );
    //
    // const querySnapshot = await getDocs<T>(q);

    return docs
      .sort((a, b) => {
        const aField = a.get(this.orderField as string);
        const bField = b.get(this.orderField as string);

        if ('asc' === this.orderDirection) {
          if ('string' === typeof aField) {
            return aField.localeCompare(bField);
          }

          return aField - bField;
        }

        if ('string' === typeof aField) {
          return bField.localeCompare(aField);
        }

        return bField - aField;
      })
      .map(d => ({
        id: d.id,
        ...(d.data() as T),
      }));
  }

  public async search(
    q: string,
    options: SearchOptionsProp = {},
  ): Promise<SearchResponse<DocumentType<T>> | undefined> {
    if (!this.canDoSearch) {
      return undefined;
    }

    const index = `${this.collectionName}`;

    // Recherches avec Typesense
    const wheres = options.wheres
      ? mergeWith(options.wheres, this.wheres)
      : this.wheres;

    const filters = getTypesenseFilters(wheres);

    if (!this.canDoSearch) {
      return undefined;
    }

    return client
      .collections<DocumentType<T>>(index)
      .documents()
      .search({
        q,
        filter_by: filters,
        page: (options.page || 0) + 1,
        per_page: this.perPage,
        query_by: this.queryBy,
      });
  }

  public ref(id: string): FirebaseFirestore.DocumentReference {
    return doc(this.getFirestore(), this.collectionName, id);
  }

  public async set(id: string, values: Partial<T>): Promise<void> {
    if (!auth.currentUser) {
      throw new Error(
        "L'utilisateur doit être connecté pour mettre à jour un document",
      );
    }

    const ref = doc<T>(this.getCollectionRef(), id);
    const documentSnapshot = await getDoc<T>(ref);

    const data = documentSnapshot.data();
    const newData = this.clean(
      {
        ...data,
        ...values,
      },
      true,
    );

    return setDoc<T>(ref, newData as T);
  }

  public async update(id: string, values: Partial<T>): Promise<void> {
    if (!auth.currentUser) {
      throw new InneditError(
        'update/auth-user-logout',
        "L'utilisateur doit être connecté pour mettre à jour un document",
      );
    }

    const ref = doc<T>(this.getCollectionRef(), id);
    const snapshot = await getDoc(ref);

    const newValues = this.clean({ ...snapshot.data(), ...values }, true);

    return updateDoc(ref, newValues as FirebaseFirestore.UpdateData<T>);
  }

  protected allRequiredIsValid = (values?: { [key: string]: any }): boolean => {
    const requiredKeys = this.extractRequiredAttributes();

    Object.keys(requiredKeys).forEach(key => {
      if (requiredKeys[key] && 'boolean' === typeof requiredKeys[key]) {
        // le champs est obligatoire
        if (!values || !values[key]) {
          throw new Error(`${key} obligatoire`);
        }
      }
    });

    return true;
  };

  public clean(values?: Partial<T>, validate?: boolean): Partial<T> {
    const date = dayjs();

    if (validate && !this.allRequiredIsValid(values)) {
      throw new InneditError('clean/not-validated', 'Non validé');
    }

    const createdAt = values?.createdAt || date.toISOString();

    const cleanData = removeUndefined({
      ...values,
      createdAt,
      archived: values?.archived ?? false,
      datetime:
        values?.datetime && !Number.isNaN(values.datetime)
          ? values.datetime
          : dayjs(createdAt).valueOf(),
      deleted: values?.deleted ?? false,
      espaceId: this.espaceId,
      hidden: values?.hidden || false,
      nbChildren: values?.nbChildren ?? 0,
      parentCollectionName:
        values?.parentCollectionName ?? this.parentCollectionName,
      parentId: values?.parentId ?? this.parentId,
      updatedAt: date.toISOString(),
      updatedByUser: auth.currentUser?.uid,
    });

    return {
      ...cleanData,
      parent: values?.parent || '',
    } as Partial<T>;
  }

  public async resetIndex(): Promise<void> {
    // TODO voir si c'est ok de toujours mettre le espaceId pour le reset ? non
    if (this.espaceId) {
      // On supprime tous les articles de cet espace de l'index et on ajout les documents existants
      await client
        .collections(this.collectionName)
        .documents()
        .delete({ filter_by: `espaceId:=${this.espaceId}` });

      const batch = writeBatch(firestore);

      const constaints = [where('deleted', '==', false)];

      if (this.espaceId) {
        constaints.push(where('espaceId', '==', this.espaceId));
      }

      // TODO traiter tous les documents - la limite de 500 est liée au batch
      constaints.push(limit(500));
      const q = query(this.getCollectionRef(), ...constaints);

      const querySnapshot = await getDocs(q);
      if (querySnapshot.size > 0) {
        const date = dayjs().toISOString();
        querySnapshot.docs.forEach(d => {
          batch.update(d.ref, {
            updatedAt: date,
          } as any);
        });

        await batch.commit();
      }
    }
  }

  public watch(
    next: (docs: DocumentType<T>[]) => void,
    options?: WatchOptionsProp,
  ): FirebaseFirestore.Unsubscribe {
    let constraints = [where('deleted', '==', false)];

    const wheres = options?.wheres
      ? mergeWith(options.wheres, this.wheres)
      : this.wheres;

    constraints = updateConstraints(constraints, {
      wheres,
      orderDirection: options?.orderDirection || this.orderDirection,
      orderField: (options?.orderField || this.orderField) as string,
    });

    if (options?.startAfter) {
      constraints.push(startAfter(options.startAfter));
    }
    constraints.push(
      limit(
        options?.limit ??
          (parseInt(String(process.env.GATSBY_INNEDIT_WATCH_LIMIT), 10) || 250),
      ),
    );

    return onSnapshot(query(this.getCollectionRef(), ...constraints), {
      next: querySnaphot => {
        next(querySnaphot.docs.map(d => ({ id: d.id, ...d.data() })));
      },
    });
  }

  public watchById(
    id: string,
    next: (
      snapshot?: DocumentType<T>,
      metadata?: FirebaseFirestore.SnapshotMetadata,
    ) => void,
  ): FirebaseFirestore.Unsubscribe {
    const ref = doc<T>(this.getCollectionRef(), id);

    return onSnapshot(ref, {
      next: snapshot => {
        if (!snapshot || !snapshot.exists() || snapshot.get('deleted')) {
          return next(undefined);
        }

        const document = {
          id,
          ...(snapshot.data() as T),
        };

        return next(document, snapshot.metadata);
      },
    });
  }

  public watchByIds(
    ids: string[],
    next: (snapshot: DocumentType<T>[]) => void,
  ): FirebaseFirestore.Unsubscribe {
    const constraints = [where(documentId(), 'in', ids)];

    const q = query(this.getCollectionRef(), ...constraints);

    return onSnapshot(q, {
      next: snapshot =>
        next(
          snapshot.docs.map(d => ({
            id: d.id,
            ...d.data(),
          })),
        ),
    });
  }
}

export default Model;
