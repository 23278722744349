import { DocumentType, PaymentType } from '@innedit/innedit-type';
import { Group } from 'packages/formidable';
import { PaiementData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import ItemRequiresCapture from '~/datas/Paiement/Manager/RequiresCapture';
import RequiresPaymentMethod from '~/datas/Paiement/Manager/RequiresPaymentMethod';
import ItemSucceeded from '~/datas/Paiement/Manager/Succeeded';

interface PaymentItemProps {
  espaceId: string;
  index: number;
  paymentId: string;
}
const PaymentItem: FC<PaymentItemProps> = function ({
  espaceId,
  index,
  paymentId,
}) {
  const [loading, setLoading] = useState<boolean>(true);
  const [payment, setPayment] = useState<DocumentType<PaymentType>>();

  useEffect(() => {
    let isMounted = true;
    if (paymentId) {
      const paiementData = new PaiementData({
        espaceId,
      });

      paiementData.watchById(paymentId, doc => {
        if (isMounted) {
          setLoading(false);
          setPayment(doc);
        }

        return isMounted;
      });

      // let url = `espaces/${espaceId}/payment_intents/retrieve?id=${item.paymentId}`;
      // fetch(`${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/${url}`)
      //   .then(r => r.json())
      //   .then(r => {
      //     const [{ balance_transaction }] = r.charges.data;
      //
      //     if (balance_transaction) {
      //
      //       url = `espaces/${espaceId}/balance_transactions/retrieve?id=${balance_transaction}`;
      //
      //       return fetch(`${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/${url}`);
      //     }
      //
      //     return null;
      //   })
      //   .then(r => r && r.json())
      //   .catch(toast.error);
    } else {
      setLoading(false);
    }

    return () => {
      isMounted = false;
    };
  }, [paymentId]);

  if (loading || !payment) {
    return null;
  }

  return (
    <Group
      display={0 === index ? 'ghost' : 'inside'}
      title={`Paiement n°${index + 1}`}
    >
      <dl className="grid grid-cols-3">
        {payment.amount > 0 && (
          <>
            <dt className="col-span-2">Montant du paiement</dt>
            <dd className="text-right">{payment.amount} €</dd>
          </>
        )}
        {payment.amountCapturable > 0 && (
          <>
            <dt className="col-span-2">Montant à valider</dt>
            <dd className="text-right">{payment.amountCapturable} €</dd>
          </>
        )}
        {payment.amountReceived > 0 && (
          <>
            <dt className="col-span-2">Montant reçu</dt>
            <dd className="text-right">{payment.amountReceived} €</dd>
          </>
        )}
        {payment.amountRefunded > 0 && (
          <>
            <dt className="col-span-2">Montant remboursé</dt>
            <dd className="text-right">{payment.amountRefunded} €</dd>
          </>
        )}
      </dl>
      {!['requires_capture', 'requires_payment_method'].includes(
        payment.status,
      ) && <ItemSucceeded espaceId={espaceId} paiement={payment} />}
      {'requires_capture' === payment.status &&
        'manual' === payment.captureMethod &&
        payment.amountCapturable > 0 && (
          <ItemRequiresCapture espaceId={espaceId} paiement={payment} />
        )}
      {'requires_payment_method' === payment.status && (
        <RequiresPaymentMethod espaceId={espaceId} paiement={payment} />
      )}
    </Group>
  );
};

export default PaymentItem;
