import { DocumentType, QuestionType } from '@innedit/innedit-type';
import { mergeWith } from 'lodash';

import Model, { ModelProps } from '../Model';

export interface ModelQuestionProps extends ModelProps<QuestionType> {
  parentField?: string;
}

class Question extends Model<QuestionType> {
  public parentField: string;

  constructor(props: Omit<ModelQuestionProps, 'collectionName'>) {
    super({
      ...props,
      canDoSearch: true,
      collectionName: 'questions',
      labelFields: ['text'],
      orderDirection: props?.orderDirection || 'desc',
      orderField: props?.orderField || 'createdAt',
      queryBy: 'text, description',
    });

    this.parentField = props.parentField || 'questions';

    // on ajout les conditions au wheres
    mergeWith(this.wheres, {
      parentField: this.parentField,
    });
  }

  public async create(
    data: Partial<QuestionType>,
    id?: string,
  ): Promise<DocumentType<QuestionType>> {
    return super.create({
      ...data,
      parentCollectionName: this.parentCollectionName,
      parentField: this.parentField,
      parentId: this.parentId,
    });
  }

  public initialize(data?: Partial<QuestionType>): Partial<QuestionType> {
    return super.initialize({
      parentCollectionName: this.parentCollectionName,
      parentField: this.parentField,
      parentId: this.parentId,
      ...data,
    });
  }
}

export default Question;
